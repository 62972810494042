import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import PanelProperty from "./PanelProperty";
import ServicesCustom from "./ServicesCustom";
import ClientsCustom from "./ClientsCustom";
import { useMain } from "../../../utils/ContextProvider";
import { useAuthContext } from "../../../utils/AuthProvider";
import { useDataBase } from "../../../hook/db.hook";
import Loader from "../../components/Loader";
import DateTimeCustom from "./DateTimeCustom";
import Toasts from "../../components/Toasts";
import Typography from "@material-ui/core/Typography";

const VisitAddView = () => {
  const history = useHistory();
  const [properties, setProperties] = useState("Service");
  const {
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    dataSort,
    setPayloadSortCallback,
    setDisableBtnMainCallback,
    btnMainRef,
  } = useMain();
  const { currentUser } = useAuthContext();
  const { getData, pushData } = useDataBase();
  const [clients, setClients] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [selectClient, setSelectClient] = useState("");
  const [selectDate, setSelectDate] = useState({ selectedDay: null });
  const [selectTime, setSelectTime] = useState("");
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  const clickAddServiceMainBtnHandler = useCallback(() => {
    setProperties("Client");
  }, []);

  const clickAddClientMainBtnHandler = useCallback(() => {
    setProperties("Date");
  }, []);

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const clickSendFormMainBtnHandler = useCallback(
    async (event) => {
      event.preventDefault();
      const servicesChecked = dataSort.filter((el) => el.checked);
      if (
        servicesChecked.length &&
        selectClient &&
        selectDate.selectedDay &&
        selectTime
      ) {
        const clientChecked = clients.filter((el) => el.id === selectClient);
        const strDate = selectDate.selectedDay.toISOString().slice(0, 10);
        let duration = moment(strDate);
        let obj = {};
        servicesChecked.forEach((el, i) => {
          duration.add(el.duration);
          obj = { ...obj, [`service${i}`]: el.id };
        });
        setDisableBtnMainCallback(true);
        try {
          const res = await pushData(
            `recordsList/${currentUser.uid}/${strDate}`,
            {
              type: "open",
              start: selectTime,
              duration: duration.subtract(strDate).format("HH:mm"),
              dateCreate: moment().valueOf(),
              email: clientChecked[0].email,
              name: clientChecked[0].name,
              phone: clientChecked[0].phone,
              ...obj,
            }
          );
          setSnackbar({ open: true, message: res });
          setSelectTime("");
          setSelectClient("");
          setSelectDate({ selectedDay: null });
          setPayloadSortCallback(
            dataSort.map((el) => {
              el.checked = false;
              return el;
            })
          );
          setDisableBtnMainCallback(false);
          history.push("/visits");
        } catch (error) {
          setDisableBtnMainCallback(false);
          console.log(error);
        }
      }
    },
    [
      selectTime,
      selectDate,
      selectClient,
      dataSort,
      clients,
      currentUser,
      setPayloadSortCallback,
      setDisableBtnMainCallback,
      history,
      pushData,
    ]
  );

  const fetchServices = useCallback(async () => {
    const res = await getData("servicesList", currentUser.uid);
    const payload = res.map((el) => {
      return { ...el, checked: false };
    });
    setPayloadSortCallback(payload);
  }, [getData, currentUser, setPayloadSortCallback]);

  const fetchClients = useCallback(async () => {
    const res = await getData("clientsList", currentUser.uid);
    setClients(res);
  }, [getData, currentUser]);

  const fetchSchedule = useCallback(async () => {
    const res = await getData(`scheduleList`, currentUser.uid);
    const arrFilter = res.filter(
      (el) => new Date(el.id) >= new Date().setDate(new Date().getDate() - 1)
    );
    setSchedule(arrFilter);
  }, [getData, currentUser]);

  useEffect(() => {
    if (properties === "Service") {
      headTextCallback("Услуги");
      btnMainTextCallback("Продолжить");
      btnMainFuncCallback(() => clickAddServiceMainBtnHandler);
    } else if (properties === "Date") {
      headTextCallback("Дата, время визита");
      btnMainTextCallback("Сохранить");
      btnMainFuncCallback(() => null);
    } else {
      headTextCallback("Клиенты");
      btnMainTextCallback("Продолжить");
      btnMainFuncCallback(() => clickAddClientMainBtnHandler);
    }
  }, [
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    clickAddServiceMainBtnHandler,
    clickSendFormMainBtnHandler,
    clickAddClientMainBtnHandler,
    properties,
  ]);

  useEffect(() => {
    fetchServices();
    fetchClients();
    fetchSchedule();
    return () => {};
  }, [fetchClients, fetchServices, fetchSchedule]);

  if (dataSort.length === 0) {
    return <Typography align="center">Добавьте услугу</Typography>;
    // return <Loader value="150px" />;
  }
  if (dataSort[0].title) {
    return <Loader value="150px" />;
  }

  if (properties === "Service") {
    return (
      <>
        <PanelProperty view={properties} setView={setProperties} />
        <ServicesCustom
          services={dataSort}
          setServices={setPayloadSortCallback}
        />
      </>
    );
  }
  if (properties === "Client") {
    return (
      <>
        <PanelProperty view={properties} setView={setProperties} />
        <ClientsCustom
          clients={clients}
          radio={selectClient}
          setRadio={setSelectClient}
        />
      </>
    );
  }
  if (selectClient) {
    return (
      <>
        <PanelProperty view={properties} setView={setProperties} />
        <DateTimeCustom
          date={selectDate}
          setDate={setSelectDate}
          services={dataSort}
          schedule={schedule}
          setTime={setSelectTime}
          btnMainRef={btnMainRef}
          clickMainBtnHandler={clickSendFormMainBtnHandler}
        />
      </>
    );
  } else {
    return (
      <>
        <PanelProperty view={properties} setView={setProperties} />
        <Typography align="center">Выберите клиента из списка</Typography>
        <Toasts
          open={snackbar.open}
          onClose={closeSnackbarHandler}
          message={snackbar.message}
        />
      </>
    );
  }
};

export default VisitAddView;
