import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(233, 210, 235, 0.55)",
    padding: "5px 5px",
    borderRadius: "26px",
    margin: "0 43px",
  },
  btn: {
    fontSize: "0.75rem",
    color: "#898989",
    padding: "8px",
  },
  activeBtn: {
    fontSize: "0.75rem",
    color: "#898989",
    backgroundColor: "#f0bbdf",
    borderRadius: "17px",
    padding: "0 20px",
    "&:hover": {
      backgroundColor: "#f0bbdf",
    },
  },
}));

const PanelProperty = (props) => {
  const { view, setView } = props;
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.root}>
        <IconButton
          onClick={() => setView("Service")}
          className={view === "Service" ? classes.activeBtn : classes.btn}
        >
          Услуга
        </IconButton>
        <IconButton
          onClick={() => setView("Client")}
          className={view === "Client" ? classes.activeBtn : classes.btn}
        >
          Клиент
        </IconButton>
        <IconButton
          onClick={() => setView("Date")}
          className={view === "Date" ? classes.activeBtn : classes.btn}
        >
          Время
        </IconButton>
      </Grid>
    </>
  );
};

PanelProperty.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
};

export default PanelProperty;
