import { useState, useEffect, useCallback } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import "moment/locale/ru";
import moment from "moment";
import { useMain } from "../utils/ContextProvider";
import DropTime from "./components/DropTime";
import { useDataBase } from "../hook/db.hook";
import { useAuthContext } from "../utils/AuthProvider";
import "../style.css";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Toasts from "./components/Toasts";

const useStyle = makeStyles((theme) => ({
  timeButton: {
    padding: "4px 8px",
    color: "rgba(55, 55, 55, 0.88)",
    fontSize: "0.85rem",
    marginTop: "18px",
    border: "1px solid",
    borderRadius: "6px",
  },
  txtTimeWork: {
    color: "rgba(55, 55, 55, 0.88)",
    fontSize: "0.625rem",
    fontWeight: "bold",
  },
}));

const ScheduleView = () => {
  const classes = useStyle();
  const { setData, getData, removeData } = useDataBase();
  const { currentUser } = useAuthContext();
  const [selectedDays, setSelectedDays] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  // const [arrDateAddWorkTime, setArrDateAddWorkTime] = useState([]);
  const [workTime, setWorkTime] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const {
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    setDisableBtnMainCallback,
    btnMainRef,
  } = useMain();
  const [form, setForm] = useState({
    timeStart: "09:00",
    timeEnd: "19:00",
    breakStart: "",
    breakEnd: "",
  });

  // const modifiersStyles = {
  //   highlighted: {
  //     color: "#588c7e",
  //   },
  // };

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const FetchSchedule = useCallback(async () => {
    let objTimeWork = {};
    const res = await getData(`scheduleList`, currentUser.uid);
    const arrFilter = res.filter(
      (el) =>
        moment(el.id).valueOf() >=
        moment(moment().format("YYYY-MM-DD")).valueOf()
    );
    // const arrDate = arrFilter.map((el) => new Date(el.id));
    // setArrDateAddWorkTime(arrDate);
    arrFilter.forEach((el) => {
      objTimeWork = {
        ...objTimeWork,
        [el.id]: [
          `${el.timeStart}-${el.timeEnd}`,
          `${el.breakStart}-${el.breakEnd}`,
        ],
      };
    });
    setWorkTime(objTimeWork);
    const arrFilterRemove = res.filter(
      (el) => new Date(el.id) < new Date().setDate(new Date().getDate() - 1)
    );
    arrFilterRemove.forEach(async (el) => {
      await removeData("scheduleList", currentUser.uid, el.id);
    });
  }, [getData, removeData, currentUser]);

  const clickOpenDropTimeHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropTimeHandler = () => {
    setAnchorEl(null);
  };

  const renderDay = (day) => {
    const date = day.toISOString().split("T")[0];
    const dateStyle = {
      position: "absolute",
      bottom: 0,
      right: 0,
      fontSize: "0.6rem",
    };
    const birthdayStyle = { fontSize: "0.35rem", textAlign: "left" };
    const cellStyle = {
      height: "2.1rem",
      width: "2.1rem",
      position: "relative",
    };
    return (
      <div style={cellStyle}>
        <div style={dateStyle}>{day.getDate()}</div>
        {workTime[date] &&
          workTime[date].map((name, i) => (
            <div key={i} style={birthdayStyle}>
              {name}
            </div>
          ))}
      </div>
    );
  };

  const clickMainBtnHandler = useCallback(
    async (event) => {
      event.preventDefault();
      if (
        selectedDays.length &&
        form.timeStart !== "00:00" &&
        form.timeEnd !== "00:00"
      ) {
        setDisableBtnMainCallback(true);
        try {
          selectedDays.forEach(async (el) => {
            let strDate = el.toISOString().slice(0, 10);
            await setData(`scheduleList/${currentUser.uid}/${strDate}`, form);
          });
          setSnackbar({ open: true, message: "Cохранено" });
          setSelectedDays([]);
          setForm({
            timeStart: "09:00",
            timeEnd: "19:00",
            breakStart: "",
            breakEnd: "",
          });
          setDisableBtnMainCallback(false);
        } catch (error) {
          setDisableBtnMainCallback(false);
          console.log(error);
        }
      } else {
        setSnackbar({ open: true, message: "Выберите значения" });
      }
    },
    [selectedDays, form, setData, setDisableBtnMainCallback, currentUser]
  );

  const handleDayClick = (day, { selected }) => {
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      setSelectedDays(selectedDays.filter((_, i) => selectedIndex !== i));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const resetWorkDayClick = () => {
    try {
      if (selectedDays.length) {
        selectedDays.forEach((el) => {
          let strDate = moment(el).format("YYYY-MM-DD");
          removeData("scheduleList", currentUser.uid, strDate);
        });
      }
      setSelectedDays([]);
      setSnackbar({ open: true, message: "Выполнено" });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    headTextCallback("График работы");
    btnMainTextCallback("Сохранить");
    btnMainFuncCallback(() => null);
  }, [headTextCallback, btnMainTextCallback, btnMainFuncCallback]);

  useEffect(() => {
    FetchSchedule();
  }, [FetchSchedule, snackbar]);

  useEffect(() => {
    let btnRef = btnMainRef.current;
    btnRef.addEventListener("click", clickMainBtnHandler);
    return () => {
      btnRef.removeEventListener("click", clickMainBtnHandler);
    };
  }, [btnMainRef, clickMainBtnHandler]);

  // console.log(form);

  return (
    <>
      <Grid container justify="center">
        <DayPicker
          className="DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)"
          selectedDays={selectedDays}
          onDayClick={handleDayClick}
          // modifiers={{ highlighted: arrDateAddWorkTime }}
          // modifiersStyles={modifiersStyles}
          localeUtils={MomentLocaleUtils}
          locale="ru"
          disabledDays={[{ before: new Date() }]}
          renderDay={renderDay}
        />
      </Grid>
      <Divider />
      <Grid container style={{ marginTop: "72px" }}>
        <Grid item xs={6}>
          <Typography align="center" className={classes.txtTimeWork}>
            Начало рабочего дня
          </Typography>
          <Grid container justify="center">
            <IconButton
              value={form.timeStart}
              title="timeStart"
              className={classes.timeButton}
              onClick={clickOpenDropTimeHandler}
            >
              {form.timeStart ? form.timeStart : "09:00"}
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography align="center" className={classes.txtTimeWork}>
            Конец рабочего дня
          </Typography>
          <Grid container justify="center">
            <IconButton
              value={form.timeEnd}
              title="timeEnd"
              className={classes.timeButton}
              onClick={clickOpenDropTimeHandler}
            >
              {form.timeEnd ? form.timeEnd : "19:00"}
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" className={classes.txtTimeWork}>
            Перерыв
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="center">
            <IconButton
              value={form.breakStart}
              title="breakStart"
              className={classes.timeButton}
              onClick={clickOpenDropTimeHandler}
            >
              {form.breakStart ? form.breakStart : "00:00"}
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justify="center">
            <IconButton
              value={form.breakEnd}
              title="breakEnd"
              className={classes.timeButton}
              onClick={clickOpenDropTimeHandler}
            >
              {form.breakEnd ? form.breakEnd : "00:00"}
            </IconButton>
          </Grid>
          <DropTime
            setForm={setForm}
            quantityHours={24}
            stepMinutes={15}
            open={Boolean(anchorEl)}
            handleClose={closeDropTimeHandler}
            ref={anchorEl}
          />
        </Grid>
        <Grid
          container
          justify="center"
          style={{
            marginTop: "5rem",
          }}
        >
          <IconButton
            style={{
              border: "1px solid #c6c6c6",
              borderRadius: "6px",
              padding: "6px",
            }}
            onClick={resetWorkDayClick}
          >
            <Typography
              align="center"
              style={{
                fontSize: "10px",
                fontWeight: "bold",
                color: "rgba(55, 55, 55, 0.88)",
              }}
            >
              Сбросить график в выбранный день
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Toasts
        open={snackbar.open}
        onClose={closeSnackbarHandler}
        message={snackbar.message}
      />
    </>
  );
};

export default ScheduleView;
