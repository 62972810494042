import { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { flagRussiaIcon, logoIcon } from "../theme/icons";
import NumberFormatCustom from "./components/NumberFormatCustom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SvgIcon from "@material-ui/core/SvgIcon";
import { useAuthHook } from "../hook/auth.hook";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useMain } from "../utils/ContextProvider";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    marginTop: "11.7px",
  },
}));

const RegisterView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loading, register } = useAuthHook();
  const { setPrevLocation } = useMain();
  const [form, setForm] = useState({ email: "", phone: "", password: "" });

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const submitHandler = async (event) => {
    try {
      event.preventDefault();
      setPrevLocation(history.location.pathname);
      await register(form);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "5px",
        }}
      >
        <Typography
          component={NavLink}
          to="/"
          style={{
            fontSize: "2rem",
            color: "#afacac",
            textDecoration: "none",
          }}
        >
          &times;
        </Typography>
      </Grid>
      <Grid style={{ width: "250px", margin: "0 auto" }}>{logoIcon}</Grid>
      <Typography
        align="center"
        style={{ marginBottom: "10px", fontWeight: "bold", color: "#4f4e4e" }}
      >
        Регистрация
      </Typography>
      <form onSubmit={submitHandler}>
        <OutlinedInput
          name="email"
          type="email"
          value={form.email}
          onChange={changeHandler}
          required
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <Typography>Email</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          name="phone"
          value={form.phone}
          onChange={changeHandler}
          required
          fullWidth
          inputComponent={NumberFormatCustom}
          inputProps={{ format: "(###)###-##-##", mask: "_" }}
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon>{flagRussiaIcon}</SvgIcon>
              <Typography>&nbsp;+7</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          name="password"
          type="password"
          value={form.password}
          onChange={changeHandler}
          required
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <Typography>Пароль</Typography>
            </InputAdornment>
          }
        />
        <FormControlLabel
          style={{ display: "flex", justifyContent: "center", marginRight: 0 }}
          control={<Checkbox required color="primary" />}
          label={
            <Typography style={{ fontSize: "10px" }}>
              Я согласен с условиями{" "}
              <Link href="#" to="" onClick={(event) => event.preventDefault()}>
                пользовательского соглашения
              </Link>
            </Typography>
          }
        />
        <Grid className={classes.center}>
          <Button type="submit" disabled={loading}>
            Продолжить
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default RegisterView;
