const accountIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      id="path"
      d="M20.6,4.6h0a2.819,2.819,0,0,1,0,5.333V21.548a.952.952,0,0,1-1.9,0V9.929a2.819,2.819,0,0,1,0-5.333V3.452a.952.952,0,0,1,1.9,0V4.6Zm-.952,3.619a.9.9,0,0,0,.952-.952.952.952,0,0,0-1.9,0,.9.9,0,0,0,.952.952ZM6.31,9.833h0a2.819,2.819,0,0,1,0,5.333v6.381a.9.9,0,0,1-.952.952.9.9,0,0,1-.952-.952V15.167a2.819,2.819,0,0,1,0-5.333V3.452A.9.9,0,0,1,5.357,2.5a.9.9,0,0,1,.952.952V9.833Zm-.952,3.619a.952.952,0,1,0,0-1.9.952.952,0,1,0,0,1.9Zm8.1-10V15.071a2.819,2.819,0,0,1,0,5.333v1.143a.952.952,0,1,1-1.9,0V20.4a2.819,2.819,0,0,1,0-5.333V3.452a.952.952,0,1,1,1.9,0ZM12.5,18.69a.952.952,0,1,0-.952-.952.9.9,0,0,0,.952.952Z"
      transform="translate(-2.5 -2.5)"
      fill="#d47fa6"
    />
  </svg>
);

const serviceIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.947"
    height="22"
    viewBox="0 0 21.947 22"
  >
    <path
      id="path"
      d="M4.5,11.9l1.2,10c0,.3.4.6.7.6H20.6c.3,0,.7-.3.7-.6l1.2-10a.366.366,0,0,0-.4-.4H4.9c-.3,0-.4.1-.4.4Zm-2,.2A2.288,2.288,0,0,1,4.8,9.5H22.1a2.288,2.288,0,0,1,2.3,2.6l-1.2,10a2.791,2.791,0,0,1-2.7,2.4H6.4a2.78,2.78,0,0,1-2.7-2.4l-1.2-10ZM6.5,8a.945.945,0,0,1-1-1,.945.945,0,0,1,1-1h14a.945.945,0,0,1,1,1,.945.945,0,0,1-1,1ZM9,4.5a.945.945,0,0,1-1-1,.945.945,0,0,1,1-1h9a.945.945,0,0,1,1,1,.945.945,0,0,1-1,1Z"
      transform="translate(-2.476 -2.5)"
      fill="#d47fa6"
    />
  </svg>
);

const scheduleIcon = (
  <svg
    id="noun_clock_428636"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 90 90"
  >
    <path
      id="Path_1290"
      data-name="Path 1290"
      d="M45,0A45,45,0,1,0,90,45,44.94,44.94,0,0,0,45,0Zm0,82A37,37,0,1,1,82,45,37.044,37.044,0,0,1,45,82Z"
    />
    <path
      id="Path_1291"
      data-name="Path 1291"
      d="M49,43.3V21a4,4,0,0,0-8,0V44a6.511,6.511,0,0,0,2,4.6L56.8,61.4a4.049,4.049,0,0,0,2.7,1.1,3.742,3.742,0,0,0,2.9-1.3,4.08,4.08,0,0,0-.2-5.7Z"
    />
  </svg>
);

const clientIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.5"
    height="16"
    viewBox="0 0 22.5 16"
  >
    <path
      id="path"
      d="M18.4,18.5a1.96,1.96,0,0,1-2,1.5H4.5a2.006,2.006,0,0,1-2-2v-.5c0-.3.1-.5.1-.8a10.378,10.378,0,0,1,.3-1.2c.6-1.3,4.7-2.9,7.6-2.9a14.988,14.988,0,0,1,4.5.9,9.838,9.838,0,0,1,4-1c2.2,0,5.2,1.3,5.7,2.3a2.509,2.509,0,0,1,.2,1c0,.2.1.4.1.6v.4a1.774,1.774,0,0,1-1.7,1.8H18.4v-.1Zm-1.1-3.7a1.68,1.68,0,0,1,.7.8,6.213,6.213,0,0,1,.3,1H23a.6.6,0,0,0-.1-.4c0-.1,0-.3-.1-.3a.349.349,0,0,0-.2-.1,9.292,9.292,0,0,0-1-.5,6.967,6.967,0,0,0-2.7-.6,2.265,2.265,0,0,0-1.6.1ZM19,11.5a2.946,2.946,0,0,1-3-3,3,3,0,0,1,6,0,2.946,2.946,0,0,1-3,3Zm0-2a.945.945,0,0,0,1-1,1,1,0,0,0-2,0,.945.945,0,0,0,1,1Zm-8.5,1.9A3.757,3.757,0,0,1,6.8,7.7,3.757,3.757,0,0,1,10.5,4a3.757,3.757,0,0,1,3.7,3.7,3.757,3.757,0,0,1-3.7,3.7Zm0-2a1.752,1.752,0,0,0,1.7-1.7,1.7,1.7,0,1,0-3.4,0,1.752,1.752,0,0,0,1.7,1.7Zm5.8,7.1a1.38,1.38,0,0,0-.4-.3c-.4-.2-.9-.5-1.4-.7a11.574,11.574,0,0,0-4-.9,10.414,10.414,0,0,0-4,.9c-.5.2-1,.5-1.4.7a1.381,1.381,0,0,0-.4.3h0a3.536,3.536,0,0,0-.1.6c0,.2-.1.5-.1.7V18H16.4v-.2c0-.2-.1-.5-.1-.7v-.6Z"
      transform="translate(-2.5 -4)"
      fill="#d47fa6"
    />
  </svg>
);

const messageIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20.985"
    viewBox="0 0 22 20.985"
  >
    <path
      id="path"
      d="M6.5,6.5v-2a2.006,2.006,0,0,1,2-2h14a2.006,2.006,0,0,1,2,2V14a2.006,2.006,0,0,1-2,2h-1v3.5a1,1,0,0,1-1.7.7L18.6,19H11.4L7.2,23.2a1,1,0,0,1-1.7-.7V19h-1a2.006,2.006,0,0,1-2-2V8.5a2.006,2.006,0,0,1,2-2Zm13,8.5a.945.945,0,0,1,1-1h2V4.5H8.5V14H16a.908.908,0,0,1,.7.3l2.8,2.8V15ZM6.5,8.5h-2V17h2a.945.945,0,0,1,1,1v2.1l2.8-2.8A.908.908,0,0,1,11,17h5.6l-1-1H8.5a2.006,2.006,0,0,1-2-2V8.5Z"
      transform="translate(-2.5 -2.5)"
    />
  </svg>
);

const visitIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20.846"
    viewBox="0 0 20 20.846"
  >
    <g id="calendar" transform="translate(1 1)">
      <rect
        id="Rectangle_449"
        data-name="Rectangle 449"
        width="18"
        height="17.174"
        rx="2"
        transform="translate(0 1.673)"
        fill="none"
        stroke="#d47fa6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_53"
        data-name="Line 53"
        y2="3.345"
        transform="translate(13 0)"
        fill="none"
        stroke="#d47fa6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_54"
        data-name="Line 54"
        y2="3.345"
        transform="translate(5 0)"
        fill="none"
        stroke="#d47fa6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <line
        id="Line_55"
        data-name="Line 55"
        x2="18"
        transform="translate(0 7.539)"
        fill="none"
        stroke="#d47fa6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

const feedbackIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.545"
    height="17.545"
    viewBox="0 0 17.545 17.545"
  >
    <g id="about" transform="translate(-23.75 -544.705)">
      <g id="info" transform="translate(22.5 543.455)">
        <circle
          id="Ellipse_30"
          data-name="Ellipse 30"
          cx="8.022"
          cy="8.022"
          r="8.022"
          transform="translate(2 2)"
          fill="none"
          stroke="#c0bbc4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Line_132"
          data-name="Line 132"
          y1="3.209"
          transform="translate(10.022 10.022)"
          fill="none"
          stroke="#c0bbc4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <line
          id="Line_133"
          data-name="Line 133"
          transform="translate(10.022 6.813)"
          fill="none"
          stroke="#c0bbc4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

const exitIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.1"
    height="16.1"
    viewBox="0 0 16.1 16.1"
  >
    <g
      id="Group_1594"
      data-name="Group 1594"
      transform="translate(-60.851 -757.95)"
    >
      <path
        id="path"
        d="M9.21,9.273l2.758-2.758A.727.727,0,1,0,10.94,5.486l-4,4a.727.727,0,0,0,0,1.029l4,4a.727.727,0,1,0,1.029-1.029L9.21,10.727h8.066a.727.727,0,0,0,0-1.455Zm1.517-5.818a.727.727,0,1,0,0-1.455H3.821A1.82,1.82,0,0,0,2,3.821V16.179A1.821,1.821,0,0,0,3.821,18h6.906a.727.727,0,1,0,0-1.455H3.821a.366.366,0,0,1-.367-.366V3.821a.366.366,0,0,1,.367-.366Z"
        transform="translate(58.901 756)"
        fill="#c3bfc7"
        stroke="#c3bfc7"
        strokeWidth="0.1"
      />
    </g>
  </svg>
);

const menuIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="13.5"
    viewBox="0 0 20 13.5"
  >
    <path
      id="icon"
      d="M8.75,13.5a.75.75,0,0,1,0-1.5h10.5a.75.75,0,0,1,0,1.5Zm-8-6A.75.75,0,0,1,.75,6h18.5a.75.75,0,0,1,0,1.5Zm0-6A.75.75,0,0,1,.75,0h10.5a.75.75,0,0,1,0,1.5Z"
      fill="#ee169d"
    />
  </svg>
);

const sortingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="14.9"
    viewBox="0 0 76.7 54"
  >
    <g id="noun_Sort_635915" transform="translate(0 -23)">
      <path
        id="Path_1283"
        d="M50.9,27A1.923,1.923,0,0,1,49,29H1.9A1.923,1.923,0,0,1,0,27V25a1.923,1.923,0,0,1,1.9-2H49a1.923,1.923,0,0,1,1.9,2Z"
        fill="#ee169d"
      />
      <path
        id="Path_1284"
        d="M42,43a1.923,1.923,0,0,1-1.9,2H1.9A1.923,1.923,0,0,1,0,43V41a1.923,1.923,0,0,1,1.9-2H40.1A1.923,1.923,0,0,1,42,41v2Z"
        fill="#ee169d"
      />
      <path
        id="Path_1285"
        d="M30,59a2.006,2.006,0,0,1-2,2H2a2.006,2.006,0,0,1-2-2V57a2.006,2.006,0,0,1,2-2H28a2.006,2.006,0,0,1,2,2Z"
        fill="#ee169d"
      />
      <path
        id="Path_1286"
        d="M21,75a2.006,2.006,0,0,1-2,2H2a2.006,2.006,0,0,1-2-2V73a2.006,2.006,0,0,1,2-2H19a2.006,2.006,0,0,1,2,2Z"
        fill="#ee169d"
      />
      <g id="Group_1595">
        <path id="Path_1287" d="M29,65" fill="#ee169d" />
        <path
          id="Path_1288"
          d="M29,65"
          fill="#ee169d"
          stroke="#231f20"
          strokeMiterlimit="10"
          strokeWidth="6"
        />
      </g>
      <path
        id="Path_1289"
        d="M76.4,57.4l-2.8-2.8a.967.967,0,0,0-1.4,0L66,60.8V24a.945.945,0,0,0-1-1H61a.945.945,0,0,0-1,1V60.8l-6.2-6.2a.967.967,0,0,0-1.4,0l-2.8,2.8a.967.967,0,0,0,0,1.4L60.9,70.1a2.9,2.9,0,0,0,4.2,0L76.4,58.8A.967.967,0,0,0,76.4,57.4Z"
        fill="#ee169d"
      />
    </g>
  </svg>
);

const flagRussiaIcon = (
  <svg viewBox="0 0 680 453" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Main-site,-Normal" transform="translate(-160.000000, -383.000000)">
        <g id="Group" transform="translate(160.000000, 383.000000)">
          <rect
            id="Rectangle-3:2"
            fill="#FFFFFF"
            x="0"
            y="0"
            width="680"
            height="151"
          ></rect>
          <rect
            id="Rectangle-3:2-Copy"
            fill="#061B79"
            x="0"
            y="151"
            width="680"
            height="151"
          ></rect>
          <rect
            id="Rectangle-3:2-Copy-2"
            fill="#C23729"
            x="0"
            y="302"
            width="680"
            height="151"
          ></rect>
        </g>
      </g>
    </g>
  </svg>
);

const logoIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 218.06 77.89"
  >
    <defs>
      <mask
        id="mask"
        x="0"
        y="-8.83"
        width="219.71"
        height="96"
        maskUnits="userSpaceOnUse"
      >
        <ellipse
          cx="120.72"
          cy="28.04"
          rx="2.95"
          ry="3.92"
          transform="translate(-2.05 11.11) rotate(-5.22)"
          fill="#fff"
        />
        <path
          d="M117,28.31a4.82,4.82,0,0,0,2.8,4.21,3.32,3.32,0,0,0,4-1.8,5.72,5.72,0,0,0-.16-5.32,3.54,3.54,0,0,0-4.07-1.87c-1.93.68-2.74,2.88-2.59,4.78a.76.76,0,0,0,.75.74.74.74,0,0,0,.74-.74,3.38,3.38,0,0,1,1.18-3.17,1.82,1.82,0,0,1,2.47.65,4.2,4.2,0,0,1,.58,3.7,2.81,2.81,0,0,1-.86,1.35,1.64,1.64,0,0,1-1.21.36c-1.31-.18-2-1.7-2.16-2.89a.75.75,0,1,0-1.49,0Z"
          fill="#fff"
        />
        <path
          d="M128.14,25.75a10.11,10.11,0,0,0-8.26-2.29,8.14,8.14,0,0,0-5.59,4.22,10.3,10.3,0,0,0-.88,2,.75.75,0,0,0,.19.73,11,11,0,0,0,7,2.32,8.66,8.66,0,0,0,7-4.49,6.87,6.87,0,0,0,.72-1.75c.21-.93-1.22-1.33-1.44-.39a7.17,7.17,0,0,1-3.74,4.42,7.45,7.45,0,0,1-6,.15,10,10,0,0,1-1.87-.89c-.19-.11-.38-.24-.56-.37s0,0-.08-.06l.19.73a7.74,7.74,0,0,1,4-4.77,8.27,8.27,0,0,1,8.21,1.51c.73.62,1.79-.42,1.06-1.05Z"
          fill="#fff"
        />
        <ellipse
          cx="98.33"
          cy="28.04"
          rx="3.92"
          ry="2.95"
          transform="translate(59.89 122.79) rotate(-83.83)"
          fill="#fff"
        />
        <path
          d="M100.52,28.36a3.32,3.32,0,0,1-1.81,2.75A1.5,1.5,0,0,1,97.42,31a2.46,2.46,0,0,1-1-1.19,4.2,4.2,0,0,1,.26-3.67A2.05,2.05,0,0,1,99.09,25c1.25.51,1.54,2.17,1.43,3.37a.74.74,0,0,0,.74.74.76.76,0,0,0,.75-.74,4.72,4.72,0,0,0-2-4.56,3.35,3.35,0,0,0-4.33,1.12,5.69,5.69,0,0,0-.78,5.19,3.5,3.5,0,0,0,3.73,2.54c2-.32,3.13-2.44,3.35-4.29a.76.76,0,0,0-.75-.75A.77.77,0,0,0,100.52,28.36Z"
          fill="#fff"
        />
        <path
          d="M92,26.69a8.19,8.19,0,0,1,8.27-1.33,7.72,7.72,0,0,1,3.87,4.81l.19-.73c.21-.17,0,0-.08.06s-.38.25-.58.36a9.85,9.85,0,0,1-1.87.85,9.69,9.69,0,0,1-3.07.51,6,6,0,0,1-2.89-.76A7.15,7.15,0,0,1,92.21,26c-.2-.94-1.64-.54-1.44.4a8.24,8.24,0,0,0,2.54,4.12,8.68,8.68,0,0,0,4.31,2.17,10.68,10.68,0,0,0,5.21-.74,10.08,10.08,0,0,0,2.57-1.42.73.73,0,0,0,.19-.73,9.74,9.74,0,0,0-2.82-4.53,8.14,8.14,0,0,0-6.36-1.88A9.88,9.88,0,0,0,91,25.64c-.73.62.33,1.66,1.06,1.05Z"
          fill="#fff"
        />
        <path
          d="M109,36a6.08,6.08,0,0,0,.72,2.57,4.62,4.62,0,0,0,1.61,1.59,5,5,0,0,0,4.06.39.75.75,0,0,0,.52-.91.74.74,0,0,0-.91-.52,3.48,3.48,0,0,1-2.85-.21l-.36-.22c-.18-.13.08.08-.09-.07l-.14-.13-.29-.3c-.1-.11,0,0-.08-.1l-.13-.2a4.21,4.21,0,0,1-.57-1.89.77.77,0,0,0-.75-.74A.74.74,0,0,0,109,36Z"
          fill="#fff"
        />
        <path
          d="M109,36a4.19,4.19,0,0,1-.59,1.89,3,3,0,0,1-1.1,1,3.6,3.6,0,0,1-2.9.21.74.74,0,1,0-.4,1.43,5.07,5.07,0,0,0,4.12-.39,4.75,4.75,0,0,0,1.6-1.55,6.11,6.11,0,0,0,.76-2.61A.75.75,0,0,0,109,36Z"
          fill="#fff"
        />
        <path
          d="M111.57,34.1c.1-.24-.13.16,0,0a2.28,2.28,0,0,1-.22.22c-.16.15.11-.06-.08.06l-.12.08-.2.11-.12.06a1.79,1.79,0,0,1-.39.13l-.21.05-.09,0a3.1,3.1,0,0,1-.43,0h-.2s-.27,0-.16,0a3.65,3.65,0,0,1-.74-.19s-.13-.06,0,0l-.19-.1-.11-.07-.12-.08,0,0a1.14,1.14,0,0,1-.11-.11s-.14-.16-.07-.07-.14-.25-.09-.15,0-.1,0-.14l-.06-.22,0-.1s0,.21,0,.11,0-.25,0,0c0-.09.08-.18,0,0,.06-.12-.16.15,0,0-.21.18-.07.07,0,0s-.07,0-.09,0l.1,0,.19-.05h.13a18.78,18.78,0,0,1,3,0l.15,0s.27,0,.06,0l.29.06s.26.1.14,0,0,0,0,0,0,0,0,0c.17.13-.06-.13,0,0s-.06-.17,0-.08,0-.18,0,0,0,0,0,.07c0,.19,0-.12,0,0s-.05.19-.08.28a.75.75,0,1,0,1.44.4,1.47,1.47,0,0,0-1.25-2.12,10.57,10.57,0,0,0-1.86-.12,16.28,16.28,0,0,0-1.85,0,2.52,2.52,0,0,0-1.2.34,1.23,1.23,0,0,0-.54,1.13,2.65,2.65,0,0,0,.62,1.49,3.28,3.28,0,0,0,1.75,1,4.35,4.35,0,0,0,2.55-.15A2.92,2.92,0,0,0,113,34.5a.75.75,0,0,0-1.44-.4Z"
          fill="#fff"
        />
        <path
          d="M89.63,16A23,23,0,0,1,93.48,5.17l.15-.2c-.08.1-.08.1,0,0l.29-.35c.16-.18.32-.36.49-.53s.59-.56.9-.83-.18.13,0,0l.17-.13L95.73,3,96,2.81l-1,.13c-.43-.18-.24-.11,0,0s.45.22.67.35a16.45,16.45,0,0,1,2.14,1.39A13.55,13.55,0,0,1,102.38,11c.52,1.59,3,.91,2.51-.69A16.6,16.6,0,0,0,96,.57c-1.08-.47-1.92.3-2.7,1a14.76,14.76,0,0,0-3.23,4.1A28.25,28.25,0,0,0,87,16a1.3,1.3,0,0,0,1.3,1.3,1.32,1.32,0,0,0,1.3-1.3Z"
          fill="#fff"
        />
        <path
          d="M104,11.94a20.64,20.64,0,0,1,11.75-.27,1.31,1.31,0,0,0,.7-2.52,21.79,21.79,0,0,0-4.24-.68,22.7,22.7,0,0,0-8.9,1c-1.59.52-.91,3,.69,2.51Z"
          fill="#fff"
        />
        <path
          d="M132.51,15.65c-.61-5-2-10.54-5.81-14.17-.58-.56-1.5-1.5-2.39-1.5a3.6,3.6,0,0,0-1.49.58A16.08,16.08,0,0,0,120.66,2a16.42,16.42,0,0,0-5.78,8c-.55,1.71,2.15,2.45,2.69.74a13.54,13.54,0,0,1,4.49-6.35c.22-.18.62-.47,1-.72s.67-.43,1-.62a10.56,10.56,0,0,1,1-.48h-1.41l.23.14.27.19c.31.21-.24-.22.07.06a12.2,12.2,0,0,1,.91.85c.18.2.36.39.53.6,0,0,.33.42.13.15l.25.34a23.12,23.12,0,0,1,3.72,10.74,1.44,1.44,0,0,0,1.39,1.4,1.41,1.41,0,0,0,1.4-1.4Z"
          fill="#fff"
        />
        <path
          d="M18.47,35.21l-10.24,11Q14.37,53.5,20.5,60.77H15.13L4.28,47.6V60.77H0V35.21H4.28V45c3-3.24,5.91-6.5,8.87-9.75Z"
          fill="#231f20"
        />
        <path
          d="M33,60.77a12.78,12.78,0,1,1,0-25.56,12.78,12.78,0,1,1,0,25.56Zm0-21.6A8.45,8.45,0,0,0,24.34,48,8.42,8.42,0,0,0,33,56.78,8.41,8.41,0,0,0,41.59,48,8.44,8.44,0,0,0,33,39.17Z"
          fill="#231f20"
        />
        <path d="M68.65,35.2v4H60V60.77H55.7V39.19H47.05v-4Z" fill="#231f20" />
        <path
          d="M82.73,60.77A12.78,12.78,0,1,1,95.72,48,12.62,12.62,0,0,1,82.73,60.77Zm0-21.6A8.45,8.45,0,0,0,74.1,48a8.63,8.63,0,1,0,17.25,0A8.44,8.44,0,0,0,82.73,39.17Z"
          fill="#231f20"
        />
        <path
          d="M130.79,59.67h-9.63l-1.45,3.75h-3.55l8.16-20.26h3.34l8.14,20.26h-3.54Zm-8.44-3.1h7.25L126,47.22Z"
          fill="#231f20"
        />
        <path
          d="M154.83,43.16V63.42h-3.4V46.31H141.3V63.42h-3.39V43.16Z"
          fill="#231f20"
        />
        <path
          d="M172.86,49.28,160.6,63.42h-1.84V43.16h3.39V57.29l12.26-14.13h1.84V63.42h-3.39Z"
          fill="#231f20"
        />
        <path
          d="M196.08,51.17a6.6,6.6,0,0,0-6.57-4.88,7,7,0,0,0,0,14,6.67,6.67,0,0,0,6.6-5l3.22,1a10.27,10.27,0,1,1-9.82-13.17,9.86,9.86,0,0,1,9.78,7Z"
          fill="#231f20"
        />
        <path
          d="M214.92,62.65a7,7,0,0,1-3.54.77H202.1V43.16h3.38V51.4h5.9a6.65,6.65,0,0,1,3.52.79,6.06,6.06,0,0,1,0,10.46Zm-2.14-7.87a4.48,4.48,0,0,0-1.79-.26h-5.51v5.77H211a4.23,4.23,0,0,0,1.81-.27,2.82,2.82,0,0,0,0-5.24Z"
          fill="#231f20"
        />
        <path
          d="M18.47,35.21l-10.24,11Q14.37,53.5,20.5,60.77H15.13L4.28,47.6V60.77H0V35.21H4.28V45c3-3.24,5.91-6.5,8.87-9.75Z"
          fill="#fff"
        />
        <path
          d="M33,60.77a12.78,12.78,0,1,1,0-25.56,12.78,12.78,0,1,1,0,25.56Zm0-21.6A8.45,8.45,0,0,0,24.34,48,8.42,8.42,0,0,0,33,56.78,8.41,8.41,0,0,0,41.59,48,8.44,8.44,0,0,0,33,39.17Z"
          fill="#fff"
        />
        <path d="M68.65,35.2v4H60V60.77H55.7V39.19H47.05v-4Z" fill="#fff" />
        <path
          d="M82.73,60.77A12.78,12.78,0,1,1,95.72,48,12.62,12.62,0,0,1,82.73,60.77Zm0-21.6A8.45,8.45,0,0,0,74.1,48a8.63,8.63,0,1,0,17.25,0A8.44,8.44,0,0,0,82.73,39.17Z"
          fill="#fff"
        />
        <path
          d="M130.79,59.67h-9.63l-1.45,3.75h-3.55l8.16-20.26h3.34l8.14,20.26h-3.54Zm-8.44-3.1h7.25L126,47.22Z"
          fill="#fff"
        />
        <path
          d="M154.83,43.16V63.42h-3.4V46.31H141.3V63.42h-3.39V43.16Z"
          fill="#fff"
        />
        <path
          d="M172.86,49.28,160.6,63.42h-1.84V43.16h3.39V57.29l12.26-14.13h1.84V63.42h-3.39Z"
          fill="#fff"
        />
        <path
          d="M196.08,51.17a6.6,6.6,0,0,0-6.57-4.88,7,7,0,0,0,0,14,6.67,6.67,0,0,0,6.6-5l3.22,1a10.27,10.27,0,1,1-9.82-13.17,9.86,9.86,0,0,1,9.78,7Z"
          fill="#fff"
        />
        <path
          d="M214.92,62.65a7,7,0,0,1-3.54.77H202.1V43.16h3.38V51.4h5.9a6.65,6.65,0,0,1,3.52.79,6.06,6.06,0,0,1,0,10.46Zm-2.14-7.87a4.48,4.48,0,0,0-1.79-.26h-5.51v5.77H211a4.23,4.23,0,0,0,1.81-.27,2.82,2.82,0,0,0,0-5.24Z"
          fill="#fff"
        />
        <path
          d="M96.89,69.93c2.7,2.56,5.3,3.64,7.85,3.68,3.48.07,5.64-1.86,5.64-3.84,0-1.74-1.32-3.21-3.77-3.64a16,16,0,0,0-3.08-.25H99.62V62h3.93a14.65,14.65,0,0,0,2.69-.19c1.69-.33,2.8-1.25,2.8-2.66,0-2-2.28-3.21-5-3.21a10,10,0,0,0-6.25,2.46l-2.35-3.14A14.56,14.56,0,0,1,104.26,52c5.42,0,9,3.38,9,7.18a4.73,4.73,0,0,1-2.94,4.42,6.72,6.72,0,0,1,4.3,6.14c0,4.1-3.94,7.83-9.58,7.86-3.5,0-7.21-1.34-10.81-4.6Z"
          fill="#fff"
        />
      </mask>
      <linearGradient
        id="linear-gradient"
        y1="39.17"
        x2="188.39"
        y2="39.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.04" stopColor="#ed3293" />
        <stop offset="0.21" stopColor="#e33694" />
        <stop offset="0.48" stopColor="#c94097" />
        <stop offset="0.56" stopColor="#c14398" />
        <stop offset="0.59" stopColor="#9e5393" />
        <stop offset="0.64" stopColor="#70698d" />
        <stop offset="0.68" stopColor="#4f7888" />
        <stop offset="0.71" stopColor="#3a8285" />
        <stop offset="0.74" stopColor="#338584" />
      </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g mask="url(#mask)">
        <rect
          y="-8.83"
          width="219.71"
          height="96"
          fill="url(#linear-gradient)"
        />
      </g>
    </g>
  </svg>
);

const telegramIcon = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fab"
    data-icon="telegram"
    class="svg-inline--fa fa-telegram fa-w-16"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 496 512"
  >
    <path
      // fill="currentColor"
      d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"
    ></path>
  </svg>
);

const whatsAppIcon = (
  <svg viewBox="0 0 448 512">
    <path
      // fill="#97f8ac"
      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
    />
  </svg>
);

const viberIcon = (
  <svg viewBox="0 0 512 512">
    <path
      // fill="#a483b9"
      d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"
    />
  </svg>
);

const phoneIcon = (
  <svg viewBox="0 0 448 512">
    <path
      // fill="#88ffa1"
      d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm-16.39 307.37l-15 65A15 15 0 0 1 354 416C194 416 64 286.29 64 126a15.7 15.7 0 0 1 11.63-14.61l65-15A18.23 18.23 0 0 1 144 96a16.27 16.27 0 0 1 13.79 9.09l30 70A17.9 17.9 0 0 1 189 181a17 17 0 0 1-5.5 11.61l-37.89 31a231.91 231.91 0 0 0 110.78 110.78l31-37.89A17 17 0 0 1 299 291a17.85 17.85 0 0 1 5.91 1.21l70 30A16.25 16.25 0 0 1 384 336a17.41 17.41 0 0 1-.39 3.37z"
    />
  </svg>
);

export {
  accountIcon,
  serviceIcon,
  scheduleIcon,
  clientIcon,
  messageIcon,
  visitIcon,
  feedbackIcon,
  exitIcon,
  menuIcon,
  sortingIcon,
  flagRussiaIcon,
  logoIcon,
  telegramIcon,
  whatsAppIcon,
  viberIcon,
  phoneIcon,
};
