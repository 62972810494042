import {makeStyles} from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  child: {
    display: 'flex',
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  }
}));

const Loader = props => {
  const {value, fullScreen} = props
  const classes=useStyles()

  return (
    <Container component="main" maxWidth='sm'>
      <CssBaseline/>
      <div className={fullScreen ? classes.root: classes.child} style={value? {height: `calc(100vh - ${value})`}: null}>
        <CircularProgress/>
      </div>
    </Container>
  )
}

export default Loader