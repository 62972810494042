import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment/locale/ru";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useAuthContext } from "../../../utils/AuthProvider";
import { useDataBase } from "../../../hook/db.hook";
import ModalRemove from "../../components/ModalRemove";

const useStyles = makeStyles((theme) => ({
  rootSecondaryItem: {
    right: "5px",
  },
}));

const ListVisitsView = (props) => {
  const { data, setData } = props;
  const classes = useStyles();
  const { currentUser } = useAuthContext();
  const { updateData } = useDataBase();
  const [openModal, setOpenModal] = useState(false);
  const [valuePath, setValuePath] = useState({ id: "", date: "" });
  const strDate = new Date().toISOString().slice(0, 10);
  const arrFilter = data.filter(
    (el) => new Date(strDate) < new Date(el.startDate) && el.type !== "cancel"
  );

  const removeClickHandler = async (date, id) => {
    try {
      let strDate = date.slice(0, 10);
      setOpenModal(true);
      setValuePath({ id: id, date: strDate });
    } catch (error) {
      console.log(error);
    }
  };

  const closeModalHandler = async (value) => {
    setOpenModal(false);
    try {
      if (value) {
        await updateData(
          `recordsList/${currentUser.uid}/${valuePath.date}/${valuePath.id}`,
          { type: "cancel" }
        );
        const arr = data.filter((el) => el.id !== valuePath.id);
        setData(arr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid>
        <List>
          {arrFilter.map((visit, i) => {
            return (
              <Fragment key={i}>
                <ListItem
                  style={{
                    backgroundColor: "#e9d2eb",
                    marginBottom: "9px",
                    borderRadius: "25px",
                    boxShadow: "0 3px 6px 0 rgba(183, 183, 183, 0.16)",
                  }}
                >
                  <ListItemText
                    disableTypography={true}
                    primary={
                      <Typography
                        style={{
                          fontSize: "0.81rem",
                          fontWeight: "bold",
                          color: "rgba(55, 55, 55, 0.88)",
                        }}
                      >
                        {visit.name}&nbsp;
                        {visit.type === "cancel" ? "Отменена" : null}
                      </Typography>
                    }
                    secondary={Object.keys(visit)
                      .filter((service) => /service/g.test(service))
                      .map((el, inx) => {
                        return (
                          <Typography
                            noWrap
                            key={inx}
                            style={{ fontSize: "0.81rem", color: "#989898" }}
                          >
                            {visit[el]}
                          </Typography>
                        );
                      })}
                  />
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          fontSize: "0.81rem",
                          fontWeight: "bold",
                          color: "rgba(55, 55, 55, 0.88)",
                        }}
                      >
                        +7{visit.phone}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        style={{ fontSize: "0.81rem", color: "#989898" }}
                      >
                        {moment(visit.startDate).format("DD MMM HH:mm")} <br />
                        {/* {moment(visit.endDate).format("DD MMM HH:mm")} */}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction
                    classes={{ root: classes.rootSecondaryItem }}
                  >
                    <IconButton
                      onClick={() =>
                        removeClickHandler(visit.startDate, visit.id)
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </Fragment>
            );
          })}
        </List>
      </Grid>
      <ModalRemove open={openModal} onClose={closeModalHandler} />
    </>
  );
};

ListVisitsView.propTypes = {
  data: PropTypes.array,
};

export default ListVisitsView;
