import { Switch, Redirect } from "react-router-dom";
import RouteWithLayout from "./utils/RouteWithLayout";
import { useAuthContext } from "./utils/AuthProvider";
import { useMain } from "./utils/ContextProvider";
import StartLayout from "./layouts/StartLayout";
import MainLayout from "./layouts/MainLayout/MainLayout";
import StartView from "./views/StartView";
import LoginView from "./views/LoginView";
import RegisterView from "./views/RegisterView";
import AccountView from "./views/AccountView";
import ServicesView from "./views/services/ServicesView";
import ClientsView from "./views/clients/ClientsView";
import ScheduleView from "./views/ScheduleView";
import VisitsView from "./views/visits/schedule/VisitsView";
import MessagesView from "./views/MessagesView";
import ServicesAddView from "./views/services/ServicesAddView";
import ClientsAddView from "./views/clients/ClientsAddView";
import VisitAddView from "./views/visits/custom/VisitAddView";
import Loader from "./views/components/Loader";
import FeedbackView from "./views/FeedbackView";

const Routes = () => {
  const { currentUser, ready } = useAuthContext();
  const { prevLocation } = useMain();
  if (!ready) {
    return <Loader fullScreen={true} />;
  }

  if (!!currentUser) {
    return (
      <Switch>
        <RouteWithLayout
          path="/account"
          exact
          component={AccountView}
          layout={MainLayout}
        />
        <RouteWithLayout
          path="/services"
          exact
          component={ServicesView}
          layout={MainLayout}
        />
        <RouteWithLayout
          path="/services/add"
          exact
          component={ServicesAddView}
          layout={MainLayout}
        />
        <RouteWithLayout
          path="/schedule"
          exact
          component={ScheduleView}
          layout={MainLayout}
        />
        <RouteWithLayout
          path="/clients"
          exact
          component={ClientsView}
          layout={MainLayout}
        />
        <RouteWithLayout
          path="/clients/add"
          exact
          component={ClientsAddView}
          layout={MainLayout}
        />
        <RouteWithLayout
          path="/messages"
          exact
          component={MessagesView}
          layout={MainLayout}
        />
        <RouteWithLayout
          path="/visits"
          exact
          component={VisitsView}
          layout={MainLayout}
        />
        {/* <RouteWithLayout
          path="/visits/id/:id"
          exact
          component={}
          layout={MainLayout}
        /> */}
        <RouteWithLayout
          path="/visits/add"
          exact
          component={VisitAddView}
          layout={MainLayout}
        />
        <RouteWithLayout
          path="/feedback"
          exact
          component={FeedbackView}
          layout={MainLayout}
        />
        {prevLocation === "/register" ? (
          <Redirect exact to="/account" />
        ) : (
          <Redirect exact to="/visits" />
        )}
        <Redirect exact to="/account" />
      </Switch>
    );
  }
  return (
    <Switch>
      <RouteWithLayout
        path="/"
        exact
        component={StartView}
        layout={StartLayout}
      />
      <RouteWithLayout
        path="/login"
        exact
        component={LoginView}
        layout={StartLayout}
      />
      <RouteWithLayout
        path="/register"
        exact
        component={RegisterView}
        layout={StartLayout}
      />
      <Redirect exact to="/" />
    </Switch>
  );
};

export default Routes;
