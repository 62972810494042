import { Fragment, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMain } from "../../utils/ContextProvider";
import { viberIcon, whatsAppIcon, phoneIcon } from "../../theme/icons";
import ModalRemove from "../components/ModalRemove";
import { useAuthContext } from "../../utils/AuthProvider";
import { useDataBase } from "../../hook/db.hook";
// import Loader from '../components/Loader'

import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SvgIcon from "@material-ui/core/SvgIcon";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

const ClientsView = () => {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const {
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    objRef,
  } = useMain();
  const { currentUser } = useAuthContext();
  const { getData, removeData } = useDataBase();
  const [valueId, setValueId] = useState("");
  const [clientsArr, setClientsArr] = useState([]);
  const [clientsArrSearch, setClientsArrSearch] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const removeClickHandler = (id) => {
    setOpenModal(true);
    setValueId(id);
  };

  const searchHandler = async (input) => {
    const arrSearch = clientsArr.filter((client) => {
      return client.name.toLowerCase().includes(input.toLowerCase());
    });

    setSearchInput(input);
    setClientsArrSearch(arrSearch);
  };

  const clickItemHandler = (id) => {
    const arr = clientsArr.filter((el) => el.id === id);
    objRef.current = arr;
    history.push(`${history.location.pathname}/add`);
  };

  const closeModalHandler = async (value) => {
    setOpenModal(false);
    if (value) {
      await removeData("clientsList", currentUser.uid, valueId);
      const arr = clientsArr.filter((el) => el.id !== valueId);
      setClientsArr(arr);
    }
  };

  const fetchClients = useCallback(async () => {
    try {
      const res = await getData("clientsList", currentUser.uid);
      setClientsArr(res);
      setClientsArrSearch(res);
    } catch (error) {
      console.log(error);
    }
  }, [getData, currentUser]);

  const clickMainBtnHandler = useCallback(() => {
    objRef.current = null;
    history.push(`${history.location.pathname}/add`);
  }, [history, objRef]);

  useEffect(() => {
    headTextCallback("Клиенты");
    btnMainTextCallback("Добавить клиента");
    btnMainFuncCallback(() => clickMainBtnHandler);
  }, [
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    clickMainBtnHandler,
  ]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  if (clientsArr.length === 0) {
    return <Typography align="center">Добавьте клиента</Typography>;
    // return <Loader value="150px"/>;
  }

  return (
    <>
      <OutlinedInput
        // classes={{ root: { backgroundColor: "white" } }}
        style={{
          backgroundColor: "white",
          color: "#d47fa6",
          fontSize: "14px",
        }}
        placeholder="Поиск"
        fullWidth
        name="search"
        value={searchInput}
        onChange={(e) => searchHandler(e.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ fill: "#d47fa6" }} />
          </InputAdornment>
        }
      />
      <List>
        {clientsArrSearch.map((client, i) => {
          return (
            <Fragment key={i}>
              <ListItem>
                <ListItemIcon>
                  <IconButton
                    href={`https://wa.me/7${client.phone}`}
                    style={{ padding: "4px" }}
                  >
                    <SvgIcon style={{ fill: "#afacac" }}>
                      {whatsAppIcon}
                    </SvgIcon>
                  </IconButton>
                  <IconButton
                    href={`viber://chat?number=%2B7${client.phone}`}
                    style={{ padding: "4px" }}
                  >
                    <SvgIcon style={{ fill: "#afacac" }}>{viberIcon}</SvgIcon>
                  </IconButton>
                  <IconButton
                    href={`tel:+7${client.phone}`}
                    style={{ padding: "4px" }}
                  >
                    <SvgIcon style={{ fill: "#afacac" }}>{phoneIcon}</SvgIcon>
                  </IconButton>
                </ListItemIcon>
                <IconButton
                  style={{ padding: "0" }}
                  onClick={() => clickItemHandler(client.id)}
                >
                  <ListItemText
                    style={{ margin: "0" }}
                    primary={
                      <Typography
                        style={{
                          fontSize: "0.81rem",
                          fontWeight: "bold",
                          color: "rgba(55, 55, 55, 0.88)",
                        }}
                      >
                        {client.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        style={{ fontSize: "0.81rem", color: "#989898" }}
                      >
                        {client.phone}
                      </Typography>
                    }
                  />
                </IconButton>

                <ListItemSecondaryAction>
                  <IconButton onClick={() => removeClickHandler(client.id)}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider style={{ margin: "0 15px" }} />
            </Fragment>
          );
        })}
      </List>
      <ModalRemove open={openModal} onClose={closeModalHandler} />
    </>
  );
};

export default ClientsView;
