import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#e28bce",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "white",
        width: "11.1rem",
        fontWeight: "bold",
        background:
          "transparent linear-gradient(90deg, #FC0B9C 0%, #20AAA5 100%) 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "19px",
        textTransform: "none",
      },
    },
    MuiInputBase: {
      root: {
        color: "#747474",
        backgroundColor: "#e9d2eb",
        boxShadow: "0 3px 6px 0 rgba(183, 183, 183, 0.16)",
        fontSize: "0.875rem",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "28px",
        margin: "3px 0",
      },
      input: {
        padding: "13px 14px",
        "&:focus": {
          borderRadius: "0 28px 28px 0",
          backgroundColor: "rgba(0,0,0,0.05)",
        },
      },
    },
    PrivateNotchedOutline: {
      root: {
        borderStyle: "none",
        borderWidth: "0",
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: "0",
      },
    },
    // Cell: {
    //   today: {
    //     backgroundColor: "inherit",
    //     color: "#e28bce",
    //   },
    //   selected: {
    //     backgroundColor: "#e28bce",
    //   },
    //   highlightedText: {
    //     color: "#e28bce",
    //   },
    // },
  },
});

export default theme;
