import Snackbar from '@material-ui/core/Snackbar'

const Toasts = props => {
  const {open, onClose, message} = props

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: "right",
      }}

      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      message={message}
    />
  )
}

export default Toasts