import { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDataBase } from "../../hook/db.hook";
import { useMain } from "../../utils/ContextProvider";
import { useAuthContext } from "../../utils/AuthProvider";
import DropTime from "../components/DropTime";
import NumberFormatCustom from "../components/NumberFormatCustom";
import Toasts from "../components/Toasts";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles((theme) => ({
  labelInput: {
    fontWeight: "bold",
    color: "#989898",
    fontSize: "0.75rem",
  },
  adornedEnd: {
    paddingRight: "7px",
  },
  input: {
    textAlign: "left",
    "&:focus": {
      borderRadius: "0",
    },
  },
}));

const ServicesAddView = () => {
  const history = useHistory();
  const classes = useStyles();
  const { currentUser } = useAuthContext();
  const durationRef = useRef(null);
  const { pushData, updateData } = useDataBase();
  const [visibleDurationTime, setVisibleDurationTime] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [form, setForm] = useState({
    name: "",
    price: "",
    duration: "",
  });
  const {
    headTextCallback,
    setDisableBtnMainCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    btnMainRef,
    objRef,
  } = useMain();

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const changeObjRefHandler = useCallback(() => {
    if (!!objRef.current) setForm(...objRef.current);
  }, [objRef]);

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const clickOpenDropTimeHandler = (event) => {
    setAnchorEl(durationRef.current);
    setVisibleDurationTime(!visibleDurationTime);
  };

  const closeDropTimeHandler = () => {
    setAnchorEl(null);
    setVisibleDurationTime(!visibleDurationTime);
  };

  const clickMainBtnHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setDisableBtnMainCallback(true);
      try {
        if (!!objRef.current) {
          if (form.name && form.price && form.duration !== "00:00") {
            const res = await updateData(
              `servicesList/${currentUser.uid}/${form.id}`,
              {
                name: form.name,
                price: form.price,
                duration: form.duration,
              }
            );
            setSnackbar({ open: true, message: res });
            history.push("/services");
          } else {
            setSnackbar({ open: true, message: "Заполните поля" });
          }
        } else {
          if (form.name && form.price && form.duration !== "00:00") {
            const res = await pushData(`servicesList/${currentUser.uid}`, {
              ...form,
              removed: false,
            });
            setSnackbar({ open: true, message: res });
            setForm({ name: "", price: "", duration: "" });
            history.push("/services");
          } else {
            setSnackbar({ open: true, message: "Заполните поля" });
          }
        }
        setDisableBtnMainCallback(false);
      } catch (error) {
        setDisableBtnMainCallback(false);
        console.log(error);
      }
    },
    [
      pushData,
      updateData,
      setDisableBtnMainCallback,
      objRef,
      currentUser,
      form,
      history,
    ]
  );

  useEffect(() => {
    headTextCallback("Услуга");
    btnMainTextCallback("Сохранить");
    btnMainFuncCallback(() => null);
    changeObjRefHandler();
  }, [
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    changeObjRefHandler,
  ]);

  useEffect(() => {
    let btnRef = btnMainRef.current;
    btnRef.addEventListener("click", clickMainBtnHandler);
    return () => {
      btnRef.removeEventListener("click", clickMainBtnHandler);
    };
  }, [btnMainRef, clickMainBtnHandler]);

  return (
    <>
      <form style={{ marginTop: "75px" }}>
        <OutlinedInput
          name="name"
          value={form.name}
          onChange={changeHandler}
          required
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>Название</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          name="price"
          value={form.price}
          onChange={changeHandler}
          required
          inputComponent={NumberFormatCustom}
          fullWidth
          classes={{ input: classes.input }}
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>Цена</Typography>
            </InputAdornment>
          }
          endAdornment={<InputAdornment position="end">&#8381;</InputAdornment>}
        />
        <Grid style={{ position: "relative" }}>
          <OutlinedInput
            disabled
            title="duration"
            value={form.duration}
            // onChange={changeHandler}
            required
            classes={{ adornedEnd: classes.adornedEnd, input: classes.input }}
            fullWidth
            ref={durationRef}
            startAdornment={
              <InputAdornment position="start">
                <Typography className={classes.labelInput}>
                  Длительность
                </Typography>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  name="icon"
                  style={{ padding: "0" }}
                  onClick={clickOpenDropTimeHandler}
                >
                  {visibleDurationTime ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <DropTime
            ref={anchorEl}
            style={{ top: "50px" }}
            setForm={setForm}
            quantityHours={8}
            stepMinutes={15}
            open={Boolean(anchorEl)}
            handleClose={closeDropTimeHandler}
          />
        </Grid>

        <FormControl variant="outlined" fullWidth>
          {/* <Select
            name="break"
            value={form.break}
            onChange={changeHandler}
            classes={{ root: classes.select }}
            native
            startAdornment={
              <InputAdornment position="start">
                <Typography className={classes.labelInput}>Перерыв</Typography>
              </InputAdornment>
            }
          >
            {[0, 15, 30, 45].map((el, i) => {
              return (
                <option key={i} value={el}>
                  {el} минут
                </option>
              );
            })}
          </Select> */}
        </FormControl>
      </form>
      <Toasts
        open={snackbar.open}
        onClose={closeSnackbarHandler}
        message={snackbar.message}
      />
    </>
  );
};

export default ServicesAddView;
