import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./Routes";
import theme from "./theme/theme";
import { ContextProvider } from "./utils/ContextProvider";
import { AuthProvider } from "./utils/AuthProvider";
// import { useNotification } from "./hook/notification.hook";
// import FirebaseApp from "./utils/FirebaseApp";

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <ContextProvider>
            <CssBaseline />
            <Routes />
          </ContextProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
