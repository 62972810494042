import { useCallback, useState } from "react";
import FirebaseApp from "../utils/FirebaseApp";
import { nanoid } from "nanoid";

export const useDataBase = () => {
  const [loading, setLoading] = useState(false);

  const uploadImgResRef = useCallback(
    async (url, file, userUid, strImg = nanoid(8)) => {
      try {
        const refImg = await FirebaseApp.storage()
          .ref(`${url}/${userUid}`)
          .child(strImg);
        await refImg.put(file).then((snapshot) => {
          console.log("upload file");
        });
        const res = await refImg.getDownloadURL().then((url) => url);
        return res;
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const queryStartGetData = useCallback(async (path, startAt, limit) => {
    try {
      const res = await FirebaseApp.database()
        .ref(path)
        .orderByKey()
        .startAt(startAt)
        .limitToFirst(limit)
        .once("value")
        .then((snapshot) => snapshot.val());
      return res;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const queryEndGetData = useCallback(async (path, endAt, limit) => {
    try {
      const res = await FirebaseApp.database()
        .ref(path)
        .orderByKey()
        .endAt(endAt)
        .limitToLast(limit)
        .once("value")
        .then((snapshot) => snapshot.val());
      return res;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const pushData = useCallback(async (path, form) => {
    setLoading(true);
    try {
      let res = ''
      await FirebaseApp.database().ref(path).push(form, error => {
        if (error) res= 'Ошибка'
        else res = 'Сохранен'
      })
      setLoading(false);
      return res
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const setData = useCallback(async (path, form) => {
    setLoading(true);
    try {
      let res = ''
      await FirebaseApp.database().ref(path).set(form, error => {
        if (error) res = 'Ошибка'
        else res= 'Сохранен'
      });
      setLoading(false);
      return res
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const updateData = useCallback(async (path, form) => {
    setLoading(true);
    try {
      let res = ''
      await FirebaseApp.database().ref(path).update(form, error => {
        if (error) {res = 'Ошибка'}
        else res = 'Сохранен'
      });
      setLoading(false);
      return res;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const getDataOn = useCallback(async (path) => {
    let data = null
    try {
      const res = await FirebaseApp.database()
        .ref(path)
        .on("value", (snapshot) => {data = snapshot.val()})
      const payload = Object.keys(data || 0).map((key) => {
        return {
          ...res[key],
          id: key,
        };
      });
      return payload;
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getData = useCallback(async (url, userUid) => {
    try {
      const res = await FirebaseApp.database()
        .ref(`${url}/${userUid}`)
        .once("value")
        .then((snapshot) => snapshot.val());
      if (url === "mastersList") return res;
      else {
        const payload = Object.keys(res || 0).map((key) => {
          return {
            ...res[key],
            id: key,
          };
        });
        return payload;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const removeData = useCallback(async (url, userUid, key) => {
    try {
      FirebaseApp.database().ref(`${url}/${userUid}/${key}`).remove();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return {
    loading,
    pushData,
    setData,
    updateData,
    getData,
    getDataOn,
    removeData,
    uploadImgResRef,
    queryStartGetData,
    queryEndGetData,
  };
};
