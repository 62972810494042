import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { logoIcon } from "../theme/icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  grid: {},
  input: {
    width: theme.spacing(8),
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  button: {},
}));

const StartView = () => {
  const classes = useStyles();
  const history = useHistory();

  const clickHandler = () => {
    history.push("/login");
  };

  return (
    <>
      <Grid style={{ paddingTop: "48px" }}>
        <Grid style={{width: "250px", margin: "0 auto"}}>{logoIcon}</Grid>
        <Typography
          align="center"
          style={{ fontSize: "0.938rem", fontWeight: "bold", color: "#989898" }}
        >
          Путеводитель в стране красоты
        </Typography>
        <Typography
          align="center"
          style={{
            fontSize: "1.563rem",
            fontWeight: "bold",
            color: "#747474",
            marginTop: "5.85rem",
          }}
        >
          Менеджер мастера
        </Typography>
        <Typography
          align="center"
          style={{ fontSize: "0.75rem", color: "#9f9f9f" }}
        >
          info@kotozapis.ru
        </Typography>
        <Grid className={classes.center}>
          <Button style={{ marginTop: "2.188rem" }} onClick={clickHandler}>
            Вход
          </Button>
        </Grid>
        <Grid className={classes.center}>
          <Typography
            component={NavLink}
            to="/register"
            align="center"
            style={{
              fontSize: "0.75rem",
              color: "#9f9f9f",
              marginTop: "0.563rem",
            }}
          >
            Регистрация
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default StartView;
