import { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { useMain } from "../../utils/ContextProvider";
import SideBar from "./SideBar";
import DropSort from "./DropSort";
import { menuIcon, sortingIcon } from "../../theme/icons";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  navHead: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  headTxt: {
    fontSize: "0.94rem",
    fontWeight: "bold",
    color: "rgba(55, 55, 55, 0.88)",
  },
  container: {
    flex: "1",
    overflow: "auto",
    backgroundColor: "#FFFFFF",
    backgroundImage:
      "radial-gradient(circle at 20% 30%, rgba(255,0,0,.1), rgba(255,0,0,0) 45%), radial-gradient(circle at 70% 80%, rgba(0,150,255,.13), rgba(0,0,255,0) 50%), radial-gradient(circle at 80% 10%, rgba(0,150,255,.05), rgba(0,0,255,0) 30%), radial-gradient(circle at 05% 60%, rgba(0,150,255,.05), rgba(0,0,255,0) 20%)",
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#676856",
    },
  },
  content: {
    overflow: "auto",
  },
  footer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    width: "15.5rem",
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(5),
  },
  text: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
  },
  drawer: {
    backgroundColor: "#000000",
    borderRadius: "5px",
    marginBottom: "9px",
    width: "134px",
    height: "5px",
  },
}));

const MainLayout = (props) => {
  const { children } = props;
  const history = useHistory();
  const classes = useStyles();
  const {
    headText,
    btnMainText,
    btnMainFunc,
    disableBtnMain,
    btnMainRef,
  } = useMain();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(!openDrawer);
  };

  const goBackHandler = () => {
    history.goBack();
  };

  const clickDropSortHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeDropSortHandler = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Grid className={classes.navHead}>
          <IconButton onClick={goBackHandler}>
            <ArrowLeftIcon style={{ fontSize: "30px", color: "#ee169d" }} />
          </IconButton>
          <Typography className={classes.headTxt}>{headText}</Typography>
          {headText === "Услуги" || headText === "Список визитов" ? (
            <IconButton
              onClick={clickDropSortHandler}
              style={{ position: "absolute", right: "48px" }}
            >
              {sortingIcon}
            </IconButton>
          ) : null}
          <DropSort ref={anchorEl} handleClose={closeDropSortHandler} />
          <IconButton onClick={toggleDrawer}>{menuIcon}</IconButton>
        </Grid>
        {/* Здесь начинается боковая панель */}
        <SideBar open={openDrawer} toggle={toggleDrawer} />
      </Container>
      <Container maxWidth="sm" className={classes.container}>
        {children}
      </Container>
      <Container maxWidth="sm" className={classes.footer}>
        <Button
          disabled={disableBtnMain}
          className={classes.button}
          onClick={btnMainFunc}
          ref={btnMainRef}
          classes={{ text: classes.text }}
        >
          {btnMainText}
        </Button>
      </Container>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
