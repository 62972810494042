import { useState, forwardRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const DropTime = forwardRef((props, ref) => {
  const { setForm, quantityHours, stepMinutes, open, handleClose } = props;
  const [duration, setDuration] = useState({ hour: "00", minute: "00" });
  const classes = useStyles();
  const hourArr = [...Array(quantityHours).keys()];
  const minuteArr = [...Array(60).keys()];

  const clickHourHandler = (el) => {
    if (!Math.floor(el / 10))
      setDuration((prev) => ({ ...prev, hour: `0${el}` }));
    else setDuration((prev) => ({ ...prev, hour: el }));
  };

  const clickMinuteHandler = (el) => {
    if (!Math.floor(el / 10))
      setDuration((prev) => ({ ...prev, minute: `0${el}` }));
    else setDuration((prev) => ({ ...prev, minute: el }));
  };

  useEffect(() => {
    if (ref) {
      ref.value = `${duration.hour}:${duration.minute}`;
      setForm((prev) => ({
        ...prev,
        [ref.title]: ref.value,
      }));
    }
  }, [duration, setForm, ref]);

  useEffect(() => {
    setDuration({ hour: "00", minute: "00" });
  }, [open]);

  return (
    <>
      <Popover
        classes={{ paper: classes.root }}
        open={open}
        anchorEl={ref}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid
          item
          xs={6}
          style={{
            height: "140px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {hourArr.map((_, i) => {
            return (
              <IconButton
                key={i}
                onClick={() => clickHourHandler(i)}
                style={{ fontSize: "0.85rem" }}
              >
                {i} ч
              </IconButton>
            );
          })}
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            height: "140px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {minuteArr.map((_, i) => {
            if (!(i % stepMinutes)) {
              return (
                <IconButton
                  key={i}
                  onClick={() => clickMinuteHandler(i)}
                  style={{ fontSize: "0.85rem" }}
                >
                  {i} мин
                </IconButton>
              );
            } else return null;
          })}
        </Grid>
      </Popover>
    </>
  );
});

export default DropTime;
