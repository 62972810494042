import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormatCustom from "../components/NumberFormatCustom";
import { flagRussiaIcon } from "../../theme/icons";
import { useMain } from "../../utils/ContextProvider";
import { useAuthContext } from "../../utils/AuthProvider";
import { useDataBase } from "../../hook/db.hook";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Toasts from "../components/Toasts";

const useStyles = makeStyles((theme) => ({
  labelInput: {
    fontWeight: "bold",
    color: "#989898",
    fontSize: "0.75rem",
  },
  adornedEnd: {
    paddingRight: "7px",
  },
  input: {
    textAlign: "left",
    "&:focus": {
      borderRadius: "0",
    },
  },
  multiline: {
    padding: "13px 14px",
  },
}));

const ServicesAddView = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    setDisableBtnMainCallback,
    btnMainRef,
    objRef,
  } = useMain();
  const { currentUser } = useAuthContext();
  const { updateData, pushData } = useDataBase();
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [form, setForm] = useState({
    name: "",
    phone: "",
    email: "",
    note: "",
  });

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const changeObjRefHandler = useCallback(() => {
    if (!!objRef.current) setForm(...objRef.current);
  }, [objRef]);

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const clickMainBtnHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setDisableBtnMainCallback(true);
      try {
        if (!!objRef.current) {
          if (form.name && form.phone) {
            const res = await updateData(
              `clientsList/${currentUser.uid}/${form.id}`,
              {
                name: form.name,
                phone: form.phone,
                email: form.email,
                note: form.note,
              }
            );
            setSnackbar({ open: true, message: res });
            history.push("/clients");
          } else {
            setSnackbar({ open: true, message: "Заполните поля" });
          }
        } else {
          if (form.name && form.phone) {
            const res = await pushData(`clientsList/${currentUser.uid}`, form);
            setForm({ name: "", phone: "", email: "", note: "" });
            setSnackbar({ open: true, message: res });
            history.push("/clients");
            // console.log("save form", res);
          } else {
            setSnackbar({ open: true, message: "Заполните поля" });
          }
        }
        setDisableBtnMainCallback(false);
      } catch (error) {
        setDisableBtnMainCallback(false);
        console.log(error);
      }
    },
    [
      pushData,
      updateData,
      setDisableBtnMainCallback,
      objRef,
      currentUser,
      form,
      history,
    ]
  );

  useEffect(() => {
    headTextCallback("Клиент");
    btnMainTextCallback("Сохранить");
    btnMainFuncCallback(() => null);
    changeObjRefHandler();
  }, [
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    changeObjRefHandler,
  ]);

  useEffect(() => {
    let btnRef = btnMainRef.current;
    btnRef.addEventListener("click", clickMainBtnHandler);
    return () => {
      btnRef.removeEventListener("click", clickMainBtnHandler);
    };
  }, [btnMainRef, clickMainBtnHandler]);

  return (
    <>
      <form style={{ marginTop: "75px" }}>
        <OutlinedInput
          name="name"
          value={form.name}
          onChange={changeHandler}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>Имя</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          name="phone"
          value={form.phone}
          onChange={changeHandler}
          inputComponent={NumberFormatCustom}
          inputProps={{ format: "(###)###-##-##", mask: "_" }}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon>{flagRussiaIcon}</SvgIcon>
              <Typography className={classes.labelInput}>&nbsp;+7</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          name="email"
          value={form.email}
          onChange={changeHandler}
          fullWidth
          type="email"
          onClick={() => console.log("input")}
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>Email</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          name="note"
          value={form.note}
          onChange={changeHandler}
          multiline
          classes={{ input: classes.input, multiline: classes.multiline }}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>
                Комментарий
              </Typography>
            </InputAdornment>
          }
        />
      </form>
      <Toasts
        open={snackbar.open}
        onClose={closeSnackbarHandler}
        message={snackbar.message}
      />
    </>
  );
};

export default ServicesAddView;
