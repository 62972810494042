import { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Radio from "@material-ui/core/Radio";

const GreenRadio = withStyles({
  root: {
    // color: green[400],
    "&$checked": {
      color: green[400],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const ClientCustom = (props) => {
  const { clients, radio, setRadio } = props;

  const radioHandler = (event) => {
    setRadio(event.target.value);
  };

  return (
    <>
      {clients.length === 0 ? (
        <Typography align="center">Добавьте клиента</Typography>
      ) : (
        <List>
          {clients.map((client, i) => {
            return (
              <Fragment key={i}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography
                        style={{
                          fontSize: "0.81rem",
                          fontWeight: "bold",
                          color: "rgba(55, 55, 55, 0.88)",
                        }}
                      >
                        {client.name}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        style={{ fontSize: "0.81rem", color: "#989898" }}
                      >
                        {client.phone}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <GreenRadio
                      value={client.id}
                      checked={radio === client.id}
                      onChange={radioHandler}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      )}
    </>
  );
};

export default ClientCustom;
