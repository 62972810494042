import { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { logoIcon } from "../theme/icons";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useAuthHook } from "../hook/auth.hook";
import Link from "@material-ui/core/Link";
import Toasts from "./components/Toasts";
import { useMain } from "../utils/ContextProvider";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    marginTop: "67.2px",
  },
  rightClose: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "5px",
  },
  closeTxt: {
    fontSize: "2rem",
    color: "#afacac",
    textDecoration: "none",
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loading, login, resetPassword } = useAuthHook();
  const { setPrevLocation } = useMain();
  const [form, setForm] = useState({ email: "", password: "" });
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const submitHandler = async (event) => {
    try {
      event.preventDefault();
      setPrevLocation(history.location.pathname);
      await login({ ...form });
    } catch (error) {
      console.log(error);
    }
  };

  const resetPasswordEmail = async (event) => {
    try {
      event.preventDefault();
      if (form.email) {
        const res = await resetPassword(form.email);
        setSnackbar({ open: true, message: res });
      } else {
        setSnackbar({ open: true, message: "Введите email" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid className={classes.rightClose}>
        <Typography component={NavLink} to="/" className={classes.closeTxt}>
          &times;
        </Typography>
      </Grid>
      <Grid style={{ width: "250px", margin: "0 auto" }}>{logoIcon}</Grid>
      <Typography
        align="center"
        style={{ marginBottom: "10px", fontWeight: "bold", color: "#4f4e4e" }}
      >
        Вход
      </Typography>
      <form onSubmit={submitHandler}>
        <OutlinedInput
          name="email"
          type="email"
          required
          value={form.email}
          onChange={changeHandler}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <Typography>Email</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          name="password"
          type="password"
          required
          value={form.password}
          onChange={changeHandler}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <Typography>Пароль</Typography>
            </InputAdornment>
          }
        />
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "18px",
          }}
        >
          <Link
            href="#"
            onClick={resetPasswordEmail}
            align="center"
            style={{
              color: "#9f9f9f",
              fontSize: "0.75rem",
              textDecoration: "underline",
            }}
          >
            Сбросить пароль
          </Link>
        </Grid>

        <Grid className={classes.center}>
          <Button type="submit" disabled={loading}>
            Продолжить
          </Button>
        </Grid>
      </form>
      <Toasts
        open={snackbar.open}
        onClose={closeSnackbarHandler}
        message={snackbar.message}
      />
    </>
  );
};

export default LoginView;
