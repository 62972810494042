import { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Checkbox from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
  root: {
    // color: green[400],
    "&$checked": {
      color: green[400],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const ServicesCustom = (props) => {
  const { services, setServices } = props;

  const checkedHandler = (id) => {
    setServices(
      services.map((service) => {
        if (service.id === id) {
          service.checked = !service.checked;
        }
        return service;
      })
    );
  };

  return (
    <>
      <List>
        {services.map((service, i) => {
          return (
            <Fragment key={i}>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        fontSize: "0.81rem",
                        fontWeight: "bold",
                        color: "rgba(55, 55, 55, 0.88)",
                      }}
                    >
                      {service.name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontSize: "0.81rem", color: "#989898" }}
                    >
                      {+service.duration.split(":")[0]
                        ? `${+service.duration.split(":")[0]} ч `
                        : ""}
                      {+service.duration.split(":")[1]
                        ? `${+service.duration.split(":")[1]} мин `
                        : ""}
                      {service.price} &#8381;
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <GreenCheckbox
                    checked={service.checked}
                    onChange={() => checkedHandler(service.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider style={{ margin: "0 15px" }} />
            </Fragment>
          );
        })}
      </List>
    </>
  );
};

export default ServicesCustom;
