import {makeStyles} from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles(theme => ({

}))

const ModalRemove = props => {
  const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={() => handleClose(false)} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Вы уверены, что хотите удалить?</DialogTitle>
      <DialogActions>
        <IconButton onClick={()=> handleClose(true)}>
          Да
        </IconButton>
        <IconButton onClick={()=> handleClose(false)}>
          Нет
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}

ModalRemove.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ModalRemove