import { Fragment, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useMain } from "../../utils/ContextProvider";
import { useDataBase } from "../../hook/db.hook";
import { useAuthContext } from "../../utils/AuthProvider";
import ModalRemove from "../components/ModalRemove";
import Loader from "../components/Loader";

import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loader: {
    display: "flex",
    height: "100%",
  },
}));

const ServicesView = () => {
  const classes = useStyles();
  const {
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    dataSort,
    setPayloadSortCallback,
    objRef,
  } = useMain();
  const history = useHistory();
  const { getData, removeData, updateData } = useDataBase();
  const { currentUser } = useAuthContext();
  const [openModal, setOpenModal] = useState(false);
  const [valueId, setValueId] = useState("");

  const removeClickHandler = (id) => {
    setOpenModal(true);
    setValueId(id);
  };

  const clickItemHandler = (id) => {
    const arr = dataSort.filter((el) => el.id === id);
    objRef.current = arr;
    history.push(`${history.location.pathname}/add`);
  };

  const closeModalHandler = async (value) => {
    setOpenModal(false);
    if (value) {
      await updateData(`servicesList/${currentUser.uid}/${valueId}`, {
        removed: true,
      });
      const arr = dataSort.filter((el) => el.id !== valueId);
      setPayloadSortCallback(arr);
    }
  };

  const fetchServices = useCallback(async () => {
    try {
      const res = await getData("servicesList", currentUser.uid);
      const resFilter = res.filter((el) => !el.removed);
      setPayloadSortCallback(resFilter);
    } catch (error) {
      console.log(error);
    }
  }, [getData, currentUser, setPayloadSortCallback]);

  const clickMainBtnHandler = useCallback(() => {
    objRef.current = null;
    history.push(`${history.location.pathname}/add`);
  }, [history, objRef]);

  useEffect(() => {
    headTextCallback("Услуги");
    btnMainTextCallback("Добавить услугу");
    btnMainFuncCallback(() => clickMainBtnHandler);
  }, [
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    clickMainBtnHandler,
  ]);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  if (dataSort.length === 0) {
    return <Typography align="center">Добавьте услугу</Typography>;
    // return (
    //   <Grid className={classes.loader}>
    //     <Loader />
    //   </Grid>
    // );
  }
  if (dataSort[0].title) {
    return (
      <Grid className={classes.loader}>
        <Loader />
      </Grid>
    );
  }
  return (
    <>
      <List>
        {dataSort.map((service, i) => {
          return (
            <Fragment key={i}>
              <ListItem button onClick={() => clickItemHandler(service.id)}>
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        fontSize: "0.81rem",
                        fontWeight: "bold",
                        color: "rgba(55, 55, 55, 0.88)",
                      }}
                    >
                      {service.name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontSize: "0.81rem", color: "#989898" }}
                    >
                      {+service.duration.split(":")[0]
                        ? `${+service.duration.split(":")[0]} ч `
                        : ""}
                      {+service.duration.split(":")[1]
                        ? `${+service.duration.split(":")[1]} мин `
                        : ""}
                      {service.price} &#8381;
                    </Typography>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => removeClickHandler(service.id)}>
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider style={{ margin: "0 15px" }} />
            </Fragment>
          );
        })}
      </List>
      <ModalRemove open={openModal} onClose={closeModalHandler} />
    </>
  );
};

export default ServicesView;
