import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    backgroundImage:
      "radial-gradient(circle at 20% 30%, rgba(255,0,0,.1), rgba(255,0,0,0) 45%), radial-gradient(circle at 70% 80%, rgba(0,150,255,.13), rgba(0,0,255,0) 50%), radial-gradient(circle at 80% 10%, rgba(0,150,255,.05), rgba(0,0,255,0) 30%), radial-gradient(circle at 05% 60%, rgba(0,150,255,.05), rgba(0,0,255,0) 20%)",
    flexGrow: "1",
    padding: "0",
    position: "relative",
  },
  container2: {
    marginTop: "50px",
    borderRadius: "180px 180px 0 0",
    flexGrow: "1",
    backgroundImage:
      "linear-gradient(#f8ede7 5%, #f2b2e8 62%, #f8ede7 85%); filter: opacity(0.9)",
  },
  paper: {
    borderRadius: theme.spacing(3),
    marginTop: theme.spacing(18),
    padding: theme.spacing(0, 1, 2.5, 1),
  },
}));
const StartLayout = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Container className={classes.container2}>
          <Paper className={classes.paper}>{children}</Paper>
        </Container>
      </Container>
    </div>
  );
};

StartLayout.propTypes = {
  children: PropTypes.node,
};

export default StartLayout;
