import Typography from '@material-ui/core/Typography'

const MessagesView = () => {
  return (
    <>
      <Typography>
        MessagesView
      </Typography>
    </>
  )
}

export default MessagesView