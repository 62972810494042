import { useState, useEffect, useCallback } from "react";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import "react-day-picker/lib/style.css";
import "moment/locale/ru";
import moment from "moment";
import { useAuthContext } from "../../../utils/AuthProvider";
import FirebaseApp from "../../../utils/FirebaseApp";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  timeButton: {
    border: "solid 0.5px #c6c6c6",
    fontSize: "0.81rem",
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
    color: "rgba(55, 55, 55, 0.88)",
    borderRadius: "15%",
    padding: "5px 5px",
  },
  timeButtonActive: {
    borderRadius: "15%",
    border: "solid 0.5px #c6c6c6",
    fontSize: "0.81rem",
    marginRight: "0.5rem",
    marginBottom: "0.5rem",
    color: "rgba(55, 55, 55, 0.88)",
    backgroundColor: "#e28bce",
    padding: "4px 4px",
    "&:hover": {
      backgroundColor: "#db70c2",
    },
  },
}));

const DateTimeCustom = (props) => {
  const {
    date,
    setDate,
    setTime,
    services,
    schedule,
    btnMainRef,
    clickMainBtnHandler,
  } = props;
  const classes = useStyles();
  const [dataWrites, setDataWrites] = useState(null);
  const [arrStepTime, setArrStepTime] = useState([]);
  const [activeButton, setActiveButton] = useState(false);
  const { currentUser } = useAuthContext();

  const showWorkDate = (day) => {
    for (let obj of schedule) {
      if (!(moment(`${obj.id}T12:00`).valueOf() - day.valueOf())) {
        return false;
      }
    }
    return true;
  };

  const calculateOpenTime = (arr, strDate, duration, scheduleDate) => {
    let arrOpenTime = [];
    let endClose = moment(strDate);

    arr.forEach((el, i) => {
      let point = moment(strDate)
        .add(el.start)
        .subtract(duration.format("HH:mm"));
      if (i === 0) {
        if (
          moment(`${strDate}T${scheduleDate[0].timeStart}`).valueOf() <=
          point.valueOf()
        ) {
          arrOpenTime.push({
            start: scheduleDate[0].timeStart,
            end: point.format("HH:mm"),
          });
        }
      } else if (endClose.valueOf() <= point.valueOf()) {
        arrOpenTime.push({
          start: endClose.format("HH:mm"),
          end: point.format("HH:mm"),
        });
      }
      endClose = moment(strDate).add(el.end);
    });
    let endWorkTime = moment(`${strDate}T${scheduleDate[0].timeEnd}`).subtract(
      duration.format("HH:mm")
    );

    if (!arr.length) {
      arrOpenTime.push({
        start: scheduleDate[0].timeStart,
        end: endWorkTime.format("HH:mm"),
      });
    } else if (endClose.valueOf() <= endWorkTime.valueOf()) {
      arrOpenTime.push({
        start: endClose.format("HH:mm"),
        end: endWorkTime.format("HH:mm"),
      });
    }
    return arrOpenTime;
  };

  const createArrTimeStep = (arrOpenTime, strDate) => {
    let arrTime = [];
    arrOpenTime.forEach((el) => {
      arrTime.push(el.start);
      let value = moment(strDate).add(el.end).diff(`${strDate}T${el.start}`);
      let count = value / 900000;
      let element = moment(strDate).add(el.start);
      for (let i = 0; i < count; i++) {
        element.add(15, "minutes");
        arrTime.push(element.format("HH:mm"));
      }
    });
    return arrTime;
  };

  const changeWritesUpdateHandler = useCallback(() => {
    const strDate = date.selectedDay.toISOString().slice(0, 10);
    const duration = moment(strDate || "");
    let arr = [];
    const visitsDate = dataWrites ? dataWrites : [];
    const servicesDate = services.filter((el) => el.checked);
    const scheduleDate = schedule.filter((el) => el.id === strDate);

    servicesDate.forEach((el) => {
      duration.add(el.duration);
    });
    if (
      scheduleDate[0].breakStart &&
      scheduleDate[0] &&
      scheduleDate[0].breakStart !== "00:00"
    ) {
      arr = [
        ...visitsDate,
        { start: scheduleDate[0].breakStart, end: scheduleDate[0].breakEnd },
      ];
    } else {
      arr = [...visitsDate];
    }
    arr = arr.sort((a, b) => {
      return (
        moment(`${strDate}T${a.start}`).valueOf() -
        moment(`${strDate}T${b.start}`).valueOf()
      );
    });

    const arrOpenTime = calculateOpenTime(arr, strDate, duration, scheduleDate);
    const arrTimeStringStep = createArrTimeStep(arrOpenTime, strDate);

    setArrStepTime(arrTimeStringStep);
  }, [dataWrites, schedule, services, date]);

  const handleDayClick = async (day, { selected }) => {
    schedule.forEach((el) => {
      if (el.id === day.toISOString().slice(0, 10)) {
        setDate({
          selectedDay: selected ? undefined : day,
        });
      }
    });
  };

  const clickTimeHandler = (e) => {
    e.preventDefault();
    setTime(e.currentTarget.value);
  };

  const changeVisitsHandler = useCallback(async () => {
    try {
      const strDate = date.selectedDay.toISOString().slice(0, 10);
      FirebaseApp.database()
        .ref(`recordsList/${currentUser.uid}/${strDate}`)
        .on("value", async (snapshot) => {
          let dataValue = await snapshot.val();
          if (dataValue) {
            const dataList = Object.keys(dataValue).map((key) => ({
              date: key,
              type: dataValue[key].type,
              start: dataValue[key].start,
              end: moment(strDate)
                .add(dataValue[key].start)
                .add(dataValue[key].duration)
                .format("HH:mm"),
            }));
            const filterVisits = dataList.filter((el) => el.type !== "cancel");
            setDataWrites(filterVisits);
          } else {
            setDataWrites(null);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, [currentUser, date]);

  useEffect(() => {
    if (date.selectedDay) {
      changeVisitsHandler();
    }
    return () => {
      if (date.selectedDay) {
        const strDate = date.selectedDay.toISOString().slice(0, 10);
        FirebaseApp.database()
          .ref(`recordsList/${currentUser.uid}/${strDate}`)
          .off();
      }
    };
  }, [changeVisitsHandler, currentUser, date]);

  useEffect(() => {
    if (date.selectedDay) {
      changeWritesUpdateHandler();
    }
  }, [changeWritesUpdateHandler, date]);

  useEffect(() => {
    let btnRef = btnMainRef.current;
    btnRef.addEventListener("click", clickMainBtnHandler);
    return () => {
      btnRef.removeEventListener("click", clickMainBtnHandler);
    };
  }, [btnMainRef, clickMainBtnHandler]);

  return (
    <>
      <Grid container justify="center">
        <DayPicker
          selectedDays={date.selectedDay}
          onDayClick={handleDayClick}
          localeUtils={MomentLocaleUtils}
          locale="ru"
          disabledDays={showWorkDate}
        />
      </Grid>
      <Divider />
      <Grid style={{ marginTop: "20px" }}>
        <Typography align="center">Время</Typography>
        {date.selectedDay ? (
          <Grid style={{ marginLeft: "1.3rem" }}>
            {services.filter((el) => el.checked).length ? (
              arrStepTime.length ? (
                arrStepTime.map((el, i) => {
                  return (
                    <IconButton
                      key={i}
                      value={el}
                      onClick={(e) => {
                        setActiveButton(i);
                        clickTimeHandler(e);
                      }}
                      className={
                        activeButton === i
                          ? classes.timeButtonActive
                          : classes.timeButton
                      }
                    >
                      {el}
                    </IconButton>
                  );
                })
              ) : (
                <Typography style={{ marginLeft: "-1.3rem" }} align="center">
                  Выберите другую дату
                </Typography>
              )
            ) : (
              <Typography style={{ marginLeft: "-1.3rem" }} align="center">
                Добавьте услугу
              </Typography>
            )}
          </Grid>
        ) : (
          <Typography align="center">Выберите дату</Typography>
        )}
      </Grid>
    </>
  );
};

export default DateTimeCustom;
