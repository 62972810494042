import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgba(233, 210, 235, 0.55)",
    padding: "5px 5px",
    borderRadius: "26px",
    margin: "0 43px",
  },
  btn: {
    fontSize: "0.75rem",
    color: "#898989",
    padding: "8px",
  },
  activeBtn: {
    fontSize: "0.75rem",
    color: "#898989",
    backgroundColor: "#f0bbdf",
    borderRadius: "17px",
    padding: "0 20px",
    "&:hover": {
      backgroundColor: "#f0bbdf",
    },
  },
}));

const ShowCurrentView = (props) => {
  const { view, setView } = props;
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.root}>
        <IconButton
          onClick={() => setView("Day")}
          className={view === "Day" ? classes.activeBtn : classes.btn}
        >
          День
        </IconButton>
        <IconButton
          onClick={() => setView("Week")}
          className={view === "Week" ? classes.activeBtn : classes.btn}
        >
          Неделя
        </IconButton>
        <IconButton
          onClick={() => setView("Month")}
          className={view === "Month" ? classes.activeBtn : classes.btn}
        >
          Месяц
        </IconButton>
        <IconButton
          onClick={() => setView("List")}
          className={view === "List" ? classes.activeBtn : classes.btn}
        >
          Список
        </IconButton>
      </Grid>
    </>
  );
};

ShowCurrentView.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
};

export default ShowCurrentView;
