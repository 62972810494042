import { useState, forwardRef } from "react";
import { useHistory } from "react-router-dom";
import { useMain } from "../../utils/ContextProvider";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const DropSort = forwardRef((props, ref) => {
  const { handleClose } = props;
  const history = useHistory();
  const [sortType, setSortType] = useState("");
  const { dataSort, setPayloadSortCallback } = useMain();

  const clickSortHandler = (type) => {
    const types = {
      name: "name",
      duration: "duration",
      price: "price",
      date: "startDate",
    };
    const sortProperty = types[type];
    let sorted = [];
    if (sortType === type) {
      sorted = [...dataSort].sort((a, b) => {
        if (sortProperty !== "price") {
          let nameA = a[sortProperty].toLowerCase();
          let nameB = b[sortProperty].toLowerCase();
          if (nameA > nameB) return -1;
          if (nameA < nameB) return 1;
          return 0;
        } else {
          return +b[sortProperty] - +a[sortProperty];
        }
      });
      setSortType("");
    } else {
      sorted = [...dataSort].sort((a, b) => {
        if (sortProperty !== "price") {
          let nameA = a[sortProperty].toLowerCase();
          let nameB = b[sortProperty].toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        } else {
          return +a[sortProperty] - +b[sortProperty];
        }
      });
      setSortType(type);
    }
    setPayloadSortCallback(sorted);
    handleClose(null);
  };

  return (
    <>
      <Menu
        anchorEl={ref}
        open={Boolean(ref)}
        onClose={handleClose}
        style={{ padding: "5px" }}
      >
        <Typography
          align="center"
          style={{ margin: "0 20px", fontSize: "0.75rem", fontWeight: "bold" }}
        >
          Упорядочивать по
        </Typography>
        <MenuItem
          onClick={() => {
            clickSortHandler("name");
          }}
          style={{
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography align="center" style={{ fontSize: "0.75rem" }}>
            Имени
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            clickSortHandler("duration");
          }}
          style={{
            minHeight: "25px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography align="center" style={{ fontSize: "0.75rem" }}>
            Длительности
          </Typography>
        </MenuItem>
        {history.location.pathname === "/visits" ? (
          <MenuItem
            onClick={() => {
              clickSortHandler("date");
            }}
            style={{
              minHeight: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography align="center" style={{ fontSize: "0.75rem" }}>
              Дате
            </Typography>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              clickSortHandler("price");
            }}
            style={{
              minHeight: "25px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography align="center" style={{ fontSize: "0.75rem" }}>
              Стоимости
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
});

export default DropSort;
