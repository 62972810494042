import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import classNames from "clsx";
import { useMain } from "../../../utils/ContextProvider";
import { useAuthContext } from "../../../utils/AuthProvider";
import { useDataBase } from "../../../hook/db.hook";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
  AppointmentTooltip,
  EditRecurrenceMenu,
} from "@devexpress/dx-react-scheduler-material-ui";
import moment from "moment";
import ListVisitsView from "./ListVisitsView";
import ShowCurrentView from "./ShowCurrentView";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Lens from "@material-ui/icons/Lens";
import AccessTime from "@material-ui/icons/AccessTime";
import { green, red } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import ModalRemove from "../../components/ModalRemove";

const styles = {
  timeScaleContainer: {
    width: "100%",
    "&>table": {
      width: "auto",
    },
  },
};

const useTooltipContentStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2, 1),
    paddingTop: 0,
    backgroundColor: theme.palette.background.paper,
    boxSizing: "border-box",
    width: "400px",
  },
  contentContainer: {
    paddingBottom: theme.spacing(1),
  },
  text: {
    ...theme.typography.body2,
    display: "inline-block",
  },
  title: {
    ...theme.typography.h6,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  icon: {
    verticalAlign: "middle",
  },
  contentItemIcon: {
    textAlign: "center",
  },
  grayIcon: {
    color: theme.palette.action.active,
  },
  colorfulContent: {
    color: ({ color }) => color[300],
  },
  lens: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    verticalAlign: "super",
  },
  textCenter: {
    textAlign: "center",
  },
  dateAndTitle: {
    lineHeight: 1.1,
  },
  titleContainer: {
    paddingBottom: theme.spacing(1),
  },
  container: {
    paddingBottom: theme.spacing(1.5),
  },
}));

const formatTimeScaleDate = (date) => moment(date).format("HH");

const TimeScaleLabel = ({ formatDate, ...restProps }) => (
  <DayView.TimeScaleLabel
    {...restProps}
    style={{}}
    formatDate={formatTimeScaleDate}
  />
);

const TimeScaleLayout = withStyles(
  styles,
  "table.makeStyles"
)(({ classes, ...restProps }) => (
  <DayView.TimeScaleLayout
    className={classes.timeScaleContainer}
    {...restProps}
  />
));

const Appointment = ({ classes, data, ...restProps }) => (
  <Appointments.Appointment
    {...restProps}
    data={data}
    style={
      data.type === "cancel"
        ? { backgroundColor: "rgba(240, 187, 223, 0.38)" }
        : { backgroundColor: "#f0bbdf" }
    } //rgba(240, 187, 223, 0.38)
  />
);

const AppointmentContent = ({ classes, data, ...restProps }) => {
  let services = Object.keys(data).filter((service) =>
    /service/g.test(service)
  );
  return (
    <Appointments.AppointmentContent {...restProps} data={data}>
      <div>
        <Typography
          style={
            data.type === "cancel"
              ? { fontSize: "11px", color: "#989898" }
              : { fontSize: "11px", fontWeight: "bold", color: "#989898" }
          } //#989898
        >
          {data.name} {data.phone}
        </Typography>
        {services.map((el, i) => {
          return (
            <Typography
              key={i}
              style={
                data.type === "cancel"
                  ? { fontSize: "14px", color: "#747474" }
                  : { fontSize: "14px", color: "#747474" }
              }
            >
              {data[el]}
            </Typography>
          );
        })}
      </div>
    </Appointments.AppointmentContent>
  );
};

const Content = ({
  children,
  appointmentData,
  formatDate,
  // ...restProps
}) => {
  let services = Object.keys(appointmentData).filter((service) =>
    /service/g.test(service)
  );
  const classes = useTooltipContentStyles(
    appointmentData.type === "cancel" ? { color: red } : { color: green }
  );
  return (
    <div className={classes.content}>
      {/* <AppointmentTooltip.Content
      {...restProps}
      appointmentData={appointmentData}
    > */}
      <Divider />
      <Grid
        container
        alignItems="flex-start"
        className={classes.titleContainer}
      >
        <Grid item xs={2} className={classNames(classes.textCenter)}>
          <Lens className={classNames(classes.lens, classes.colorfulContent)} />
        </Grid>
        <Grid item xs={9}>
          <div>
            <div className={classNames(classes.title, classes.dateAndTitle)}>
              {appointmentData.name} <br /> +7{appointmentData.phone}
            </div>
            <div className={classNames(classes.text, classes.dateAndTitle)}>
              {formatDate(appointmentData.startDate, {
                day: "numeric",
                weekday: "long",
              })}
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container alignItems="center" className={classes.contentContainer}>
        <Grid item xs={2} className={classes.textCenter}>
          <AccessTime className={classes.icon} />
        </Grid>
        <Grid item xs={10}>
          <div className={classes.text}>
            {`${formatDate(appointmentData.startDate, {
              hour: "numeric",
              minute: "numeric",
            })}
              - ${formatDate(appointmentData.endDate, {
                hour: "numeric",
                minute: "numeric",
              })}`}
          </div>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid item xs={10}>
          <Typography style={{ color: "red" }}>
            {appointmentData.type === "cancel" ? "Отменен" : null}
          </Typography>
          {services.map((el, i) => {
            return (
              <Typography
                key={i}
                style={{ fontSize: "14px", color: "#747474" }}
              >
                {appointmentData[el]}
              </Typography>
            );
          })}
          <Typography style={{ fontSize: "11px", color: "#747474" }}>
            Дата создания:&nbsp;
            {moment(appointmentData.dateCreate).format("lll")}
          </Typography>
        </Grid>
      </Grid>
      {/* </AppointmentTooltip.Content> */}
    </div>
  );
};

const VisitsView = () => {
  const history = useHistory();
  const {
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    dataSort,
    setPayloadSortCallback,
  } = useMain();
  const [currentViewName, setCurrentViewName] = useState("Month");
  const [openModal, setOpenModal] = useState(false);
  const [valuePath, setValuePath] = useState({ id: "", date: "" });
  const { currentUser } = useAuthContext();
  const {
    getData,
    updateData,
    queryStartGetData,
    queryEndGetData,
  } = useDataBase();

  const objToArr = (obj) => {
    let arr = [];
    Object.keys(obj).forEach((key1) => {
      let objChild = obj[key1];
      Object.keys(objChild).forEach((key2) => {
        arr.push({ ...objChild[key2], idVisits: key2, date: key1 });
      });
    });
    return arr;
  };

  const valueToTimeString = (value) => {
    let str = "";
    let hours = Math.floor(value / 60);
    let minutes = value % 60;

    if (!Math.floor(hours / 10)) {
      str = `0${hours}`;
      if (!minutes) return (str += `:0${minutes}`);
      return (str += `:${minutes}`);
    }
    if (!minutes) return (str = `${hours}:0${minutes}`);
    return (str = `${hours}:${minutes}`);
  };

  const fetchVisits = useCallback(async () => {
    try {
      const strDate = new Date().toISOString().slice(0, 10);
      const strEnd = new Date(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
        .slice(0, 10);

      const resEnd = await queryEndGetData(
        `recordsList/${currentUser.uid}`,
        strEnd,
        30
      );
      const resStart = await queryStartGetData(
        `recordsList/${currentUser.uid}`,
        strDate,
        30
      );
      const resServices = await getData("servicesList", currentUser.uid);
      const arrEnd = resEnd ? objToArr(resEnd) : [];
      const arrStart = resStart ? objToArr(resStart) : [];

      const arrFilter = [...arrEnd, ...arrStart].filter(
        (el) => el.type !== "temp"
      );

      const arrVisits = arrFilter.map((el) => {
        let beginHour = +el.start.split(":")[0] * 60;
        let beginMinute = +el.start.split(":")[1];
        let durationHour = +el.duration.split(":")[0] * 60;
        let durationMinute = +el.duration.split(":")[1];
        let begin = beginHour + beginMinute;
        let duration = durationHour + durationMinute;
        let end = begin + duration;
        let strEnd = valueToTimeString(end);

        let obj = {
          startDate: `${el.date}T${el.start ? el.start : ""}`,
          endDate: `${el.date}T${strEnd}`,
          // title: `${el.name} +7${el.phone}`,
          name: el.name,
          phone: el.phone,
          id: el.idVisits,
          type: el.type,
          duration: el.duration,
          // price: el.price,
          dateCreate: el.dateCreate,
        };
        console.log(el)
        let services = Object.keys(el).filter((service) =>
          /service/g.test(service)
        );
        services.forEach((service) => {
          obj[service] = resServices.filter(
            (elService) => elService.id === el[service]
          )[0].name;
        });
        console.log(obj);
        return obj;
      });

      setPayloadSortCallback(arrVisits);
    } catch (error) {
      console.log(error);
    }
  }, [
    queryStartGetData,
    queryEndGetData,
    getData,
    setPayloadSortCallback,
    currentUser,
  ]);

  const clickMainBtnHandler = useCallback(() => {
    history.push(`${history.location.pathname}/add`);
  }, [history]);

  const commitChanges = ({ deleted }) => {
    const dataCancel = dataSort.filter((el) => el.id === deleted);
    let strDate = dataCancel[0].startDate.slice(0, 10);
    setOpenModal(true);
    setValuePath({ date: strDate, id: deleted });
  };

  const closeModalHandler = async (value) => {
    setOpenModal(false);
    try {
      if (value) {
        await updateData(
          `recordsList/${currentUser.uid}/${valuePath.date}/${valuePath.id}`,
          { type: "cancel" }
        );
        const dataChange = dataSort.map((el) => {
          if (el.id === valuePath.id) {
            el.type = "cancel";
          }
          return el;
        });
        setPayloadSortCallback(dataChange);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    btnMainTextCallback("Создать визит");
    btnMainFuncCallback(() => clickMainBtnHandler);
  }, [btnMainTextCallback, btnMainFuncCallback, clickMainBtnHandler]);

  useEffect(() => {
    if (currentViewName === "List") {
      headTextCallback("Список визитов");
    } else {
      headTextCallback("Календарь визитов");
    }
  }, [headTextCallback, currentViewName]);

  useEffect(() => {
    fetchVisits();
  }, [fetchVisits, currentViewName]);

  if (currentViewName === "List") {
    return (
      <>
        <ShowCurrentView view={currentViewName} setView={setCurrentViewName} />
        <ListVisitsView data={dataSort} setData={setPayloadSortCallback} />
      </>
    );
  }

  return (
    <>
      <ShowCurrentView view={currentViewName} setView={setCurrentViewName} />
      <Scheduler data={dataSort} locale="ru" firstDayOfWeek={1} height="auto">
        <EditingState onCommitChanges={commitChanges} />
        <ViewState
          currentViewName={currentViewName}
          onCurrentViewNameChange={currentViewName}
        />
        <WeekView
          startDayHour={7}
          endDayHour={19}
          cellDuration={60}
          timeScaleLabelComponent={TimeScaleLabel}
          timeScaleLayoutComponent={TimeScaleLayout}
        />
        <DayView
          startDayHour={7}
          endDayHour={19}
          cellDuration={60}
          timeScaleLabelComponent={TimeScaleLabel}
          timeScaleLayoutComponent={TimeScaleLayout}
        />
        <MonthView />
        <Toolbar
          flexibleSpaceComponent={() => (
            <div style={{ position: "absolute", right: "2em" }}>
              {moment().format("MMMM YYYY")}
            </div>
          )}
        />
        <DateNavigator />
        <Appointments
          appointmentContentComponent={AppointmentContent}
          appointmentComponent={Appointment}
        />
        <EditRecurrenceMenu />
        <AppointmentTooltip
          showDeleteButton
          showCloseButton
          contentComponent={Content}
        />
      </Scheduler>
      <ModalRemove open={openModal} onClose={closeModalHandler} />
    </>
  );
};

export default VisitsView;
