import {
  useState,
  useContext,
  createContext,
  useCallback,
  useRef,
} from "react";

const MainContext = createContext();

export const useMain = () => {
  return useContext(MainContext);
};

export const ContextProvider = ({ children }) => {
  const [headText, setHeadText] = useState("");
  const [btnMainText, setBtnMainText] = useState("Сохранить");
  const [disableBtnMain, setDisableBtnMain] = useState(false);
  const [btnMainFunc, setBtnMainFunc] = useState(null);
  const [dataSort, setDataSort] = useState([]);
  const [prevLocation, setPrevLocation] = useState(null);
  const btnMainRef = useRef(null);
  const objRef = useRef(null);

  const headTextCallback = useCallback((txt) => {
    setHeadText(txt);
  }, []);

  const btnMainTextCallback = useCallback((txt) => {
    setBtnMainText(txt);
  }, []);

  const btnMainFuncCallback = useCallback((func) => {
    setBtnMainFunc(func);
  }, []);

  const setPayloadSortCallback = useCallback((arr) => {
    setDataSort(arr);
  }, []);

  const setDisableBtnMainCallback = useCallback((bool) => {
    setDisableBtnMain(bool);
  }, []);

  return (
    <MainContext.Provider
      value={{
        headText,
        headTextCallback,
        btnMainText,
        btnMainTextCallback,
        btnMainFunc,
        btnMainFuncCallback,
        dataSort,
        setPayloadSortCallback,
        disableBtnMain,
        setDisableBtnMainCallback,
        prevLocation,
        setPrevLocation,
        btnMainRef,
        objRef,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
