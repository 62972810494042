import { useState, useEffect, useCallback } from "react";
import { useMain } from "../utils/ContextProvider";
import { useDataBase } from "../hook/db.hook";
import { useAuthContext } from "../utils/AuthProvider";
import Toasts from "./components/Toasts";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  labelInput: {
    fontWeight: "bold",
    color: "#989898",
    fontSize: "0.95rem",
  },
  adornedEnd: {
    paddingRight: "7px",
  },
  input: {
    textAlign: "left",
    "&:focus": {
      borderRadius: "0",
    },
  },
  multiline: {
    padding: "13px 14px",
  },
}));

const FeedbackView = () => {
  const classes = useStyles();
  const { currentUser } = useAuthContext();
  const { pushData } = useDataBase();
  const {
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    btnMainRef,
  } = useMain();
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [form, setForm] = useState({ note: "" });

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const changeHandler = (event) => {
    event.preventDefault();
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const clickMainBtnHandler = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        if (form.note) {
          const strDate = new Date().toISOString();
          const res = await pushData(`feedbackList/${currentUser.uid}`, {
            note: form.note,
            createDate: strDate,
          });
          setSnackbar({ open: true, message: res });
          setForm({ note: "" });
        } else {
          setSnackbar({ open: true, message: "Заполните поля" });
        }
      } catch (error) {
        console.log(error);
      }
    },
    [currentUser, pushData, form]
  );

  useEffect(() => {
    headTextCallback("Обратная связь");
    btnMainTextCallback("Отправить");
    btnMainFuncCallback(() => null);
    return () => {};
  }, [headTextCallback, btnMainTextCallback, btnMainFuncCallback]);

  useEffect(() => {
    let btnRef = btnMainRef.current;
    btnRef.addEventListener("click", clickMainBtnHandler);
    return () => {
      btnRef.removeEventListener("click", clickMainBtnHandler);
    };
  }, [btnMainRef, clickMainBtnHandler]);

  return (
    <>
      <form style={{ marginTop: "75px" }}>
        <Typography align="center" className={classes.labelInput}>
          Комментарий
        </Typography>
        <OutlinedInput
          name="note"
          value={form.note}
          onChange={changeHandler}
          fullWidth
          multiline
          rows={15}
          classes={{ input: classes.input, multiline: classes.multiline }}
        />
      </form>
      <Toasts
        open={snackbar.open}
        onClose={closeSnackbarHandler}
        message={snackbar.message}
      />
    </>
  );
};

export default FeedbackView;
