import { useState, useEffect, useCallback, useRef } from "react";
import { useMain } from "../utils/ContextProvider";
import { useDataBase } from "../hook/db.hook";
import { useAuthContext } from "../utils/AuthProvider";
import { flagRussiaIcon } from "./../theme/icons";
import NumberFormatCustom from "./components/NumberFormatCustom";
import Toasts from "./components/Toasts";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopy";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "./components/Loader";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
  avatar: {
    width: theme.spacing(17),
    height: theme.spacing(17),
  },
  labelInput: {
    fontWeight: "bold",
    color: "#989898",
    fontSize: "0.75rem",
  },
  adornedEnd: {
    paddingRight: "7px",
  },
  inputOutlined: {
    textAlign: "left",
    "&:focus": {
      borderRadius: "0",
    },
  },
  footer: {
    backgroundColor: "#000000",
    borderRadius: "5px",
    width: "134px",
    height: "5px",
  },
  select: {
    "&:focus": {
      borderRadius: "0 28px 28px 0",
    },
  },
}));

const arrCategory = [
  "Маникюр",
  "Педикюр",
  "Мастер по волосам",
  "Ботокс волос",
  "Кератин волос",
  "Бровист",
  "Наращивание ресниц",
  "Ламинирование ресниц",
  "Ботокс ресниц",
  "Визажист",
  "Перманентный макияж",
  "Косметолог",
  "Косметик-эстетист",
  "Сангапури",
  "Аугментация губ",
  "Лазерная эпиляция",
  "Шугаринг",
  "Ваксинг",
  "Тату",
  "Массаж"
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AccountView = () => {
  const classes = useStyles();
  const {
    headTextCallback,
    btnMainTextCallback,
    btnMainFuncCallback,
    setDisableBtnMainCallback,
    btnMainRef,
  } = useMain();
  const { setData, getData, uploadImgResRef } = useDataBase();
  const inputRef = useRef(null);
  const { currentUser } = useAuthContext();
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [loadImg, setLoadImg] = useState(false);
  const [category, setCategory] = useState([]);
  const [form, setForm] = useState({
    name: "",
    aboutMe: "",
    category: "",
    address: "",
    email: currentUser.email,
    phone: "",
    imgUrl: "",
    urlClientApp: `https://booking.kotozapis.ru/${currentUser.uid}`,
  });

  const copyToClipboard = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(form.urlClientApp);
    setSnackbar({ open: true, message: "Скопирован" });
  };

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };

  const uploadImgChangeHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setLoadImg(true);
      try {
        const file = inputRef.current.files[0];
        console.log(file)
        const refImg = await uploadImgResRef(
          "beatysearch",
          file,
          currentUser.uid,
          "avatar"
        );
        setForm((f) => {
          return { ...f, imgUrl: refImg };
        });
        await currentUser
          .updateProfile({
            photoURL: refImg,
          })
          .then(() => {
            console.log("succes");
          })
          .catch((error) => {
            console.log(error);
          });
        const res = await setData(`mastersList/${currentUser.uid}`, {
          ...form,
          imgUrl: refImg,
        });
        setLoadImg(false);
        setSnackbar({ open: true, message: res });
      } catch (error) {
        setLoadImg(false);
        console.log(error);
      }
    },
    [currentUser, uploadImgResRef, form, setData]
  );

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const categoryHandler = (event) => {
    setCategory(event.target.value);
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const clickMainBtnHandler = useCallback(
    async (event) => {
      event.preventDefault();
      setDisableBtnMainCallback(true);
      try {
        await currentUser
          .updateProfile({
            displayName: form.name,
          })
          .then(() => {
            console.log("succes");
          })
          .catch((error) => {
            console.log(error);
          });
        const res = await setData(`mastersList/${currentUser.uid}`, form);
        setDisableBtnMainCallback(false);
        setSnackbar({ open: true, message: res });
      } catch (error) {
        setDisableBtnMainCallback(false);
        console.log(error);
      }
    },
    [setData, setDisableBtnMainCallback, form, currentUser]
  );

  const fetchAccount = useCallback(async () => {
    setDisableBtnMainCallback(true);
    try {
      const res = await getData("mastersList", currentUser.uid);
      setForm((f) => {
        return { ...f, ...res };
      });
      // console.log(res);
      setDisableBtnMainCallback(false);
    } catch (error) {
      console.log(error);
      setDisableBtnMainCallback(false);
    }
  }, [currentUser.uid, getData, setDisableBtnMainCallback]);

  useEffect(() => {
    headTextCallback("Профиль");
    btnMainTextCallback("Сохранить");
    btnMainFuncCallback(() => null);
  }, [headTextCallback, btnMainTextCallback, btnMainFuncCallback]);

  useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);

  useEffect(() => {
    let btnRef = btnMainRef.current;
    btnRef.addEventListener("click", clickMainBtnHandler);
    return () => {
      btnRef.removeEventListener("click", clickMainBtnHandler);
    };
  }, [btnMainRef, clickMainBtnHandler]);

  return (
    <>
      <Grid
        style={{ display: "flex", justifyContent: "center", marginTop: "31px" }}
      >
        <input
          onChange={uploadImgChangeHandler}
          ref={inputRef}
          accept="image/*"
          id="uploadAvatar"
          type="file"
          className={classes.input}
        />
        <label htmlFor="uploadAvatar">
          <IconButton component="span">
            {loadImg ? (
              <Grid container alignItems="center" className={classes.avatar}>
                <Loader fullScreen={false} />
              </Grid>
            ) : (
              <Avatar className={classes.avatar} src={form.imgUrl} />
            )}
          </IconButton>
        </label>
      </Grid>
      <form style={{ marginTop: "49px" }}>
        <OutlinedInput
          fullWidth
          disabled
          name="urlClientApp"
          value={form.urlClientApp}
          // onChange={changeHandler}
          classes={{
            adornedEnd: classes.adornedEnd,
            input: classes.inputOutlined,
          }}
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>Страница</Typography>
            </InputAdornment>
          }
          endAdornment={
            <IconButton
              onClick={copyToClipboard}
              style={{ padding: "0 0 0 5px" }}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
          }
        />
        <OutlinedInput
          fullWidth
          name="name"
          value={form.name}
          onChange={changeHandler}
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>Имя</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          fullWidth
          name="aboutMe"
          value={form.aboutMe}
          onChange={changeHandler}
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>О себе</Typography>
            </InputAdornment>
          }
        />
        <FormControl variant="outlined" fullWidth>
          <Select
            classes={{ root: classes.select }}
            multiple
            name="category"
            value={form.category.length ? form.category : category}
            onChange={categoryHandler}
            MenuProps={MenuProps}
            startAdornment={
              <InputAdornment position="start">
                <Typography className={classes.labelInput}>
                  Категория
                </Typography>
              </InputAdornment>
            }
          >
            {arrCategory.map((name, i) => (
              <MenuItem key={i} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <OutlinedInput
          style={{ marginTop: "50px" }}
          fullWidth
          name="address"
          value={form.address}
          onChange={changeHandler}
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>Адрес</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          fullWidth
          disabled
          name="email"
          type="email"
          value={form.email}
          onChange={changeHandler}
          startAdornment={
            <InputAdornment position="start">
              <Typography className={classes.labelInput}>Email</Typography>
            </InputAdornment>
          }
        />
        <OutlinedInput
          inputComponent={NumberFormatCustom}
          inputProps={{ format: "(###)###-##-##", mask: "_" }}
          name="phone"
          value={form.phone}
          onChange={changeHandler}
          fullWidth
          startAdornment={
            <InputAdornment position="start">
              <SvgIcon>{flagRussiaIcon}</SvgIcon>
              <Typography className={classes.labelInput}>&nbsp;+7</Typography>
            </InputAdornment>
          }
        />
      </form>
      <Toasts
        open={snackbar.open}
        onClose={closeSnackbarHandler}
        message={snackbar.message}
      />
    </>
  );
};

export default AccountView;
